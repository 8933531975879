export function initCardGame() {
  if (window.matchMedia("(pointer: coarse)").matches) {
    document.body.classList.add("is-touchdevice")
    const cardgame = document.querySelector(".cardgame__cards")
    if (cardgame) {
      cardgame.addEventListener("click", (e) => {
        const img = e.target.closest(".image")
        if (img) img.classList.toggle("active")
      })
    }
  }
}
