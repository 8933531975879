import { useCallback, useState } from "react"

import { hero } from "./assets.js"
import { Hero } from "./heroes.js"
import { gettext } from "./i18n.js"
import { useSaving } from "./saving.js"
import { randomIndex, useSettings } from "./utils.js"
import { useWorldActions, useWorldState } from "./world.js"

const useItem = (items) => {
  let [index, setIndex] = useState(() => randomIndex(items))
  if (!items[index]) {
    index = randomIndex()
    setIndex(index)
  }

  return {
    item: items[index],
    setNext: () => setIndex((index + 1) % items.length),
    setPrevious: () => setIndex((index + items.length - 1) % items.length),
  }
}

export const Onboarding = ({ finishOnboarding }) => {
  const { user } = useWorldState()

  const [female, setFemale] = useState(true)
  const [heroName, setHeroName] = useState(user.heroName || "")
  const { urls } = useSettings()

  const assetGroup = female ? hero.female.perspective : hero.male.perspective

  const skin = useItem(assetGroup.Skin)
  const hair = useItem(assetGroup.Hair)

  const items = ["Shoes_00", "Suit_00", skin.item, hair.item]

  const { updateWorld } = useWorldActions()
  const { Saving, setSaveState } = useSaving({
    url: urls.onboarding,
    onSuccess: useCallback(
      (success) => {
        success.world && updateWorld(success.world)
        finishOnboarding()
      },
      [finishOnboarding, updateWorld],
    ),
  })
  const saveState = () => ({
    skin: skin.item,
    hair: hair.item,
    sex: female ? "female" : "male",
    heroName,
  })

  return (
    <div className="overlay is-profile">
      <div className="profile is-onboarding apps__container">
        <form
          className="onboarding"
          onSubmit={(e) => {
            e.preventDefault()
            setSaveState(saveState())
          }}
        >
          <h1 className="onboarding__title">{gettext("Welcome")}</h1>
          <p className="onboarding__text">
            {gettext(
              "Choose your style. You will be able to buy additional clothes, equipment and accessories later.",
            )}
          </p>
          <div className="field onboarding__field">
            <label htmlFor="heroName">{gettext("Hero name")}</label>
            <input
              id="heroName"
              type="text"
              value={heroName}
              onChange={(e) => setHeroName(e.target.value)}
              maxLength="200"
              required
            />
          </div>

          <div className="buttons buttons--onboarding">
            <button
              type="button"
              className={`button ${female ? "button--selected" : ""}`}
              onClick={() => setFemale(true)}
            >
              {gettext("I want to be a heroine")}
            </button>
            <button
              type="button"
              className={`button ${female ? "" : "button--selected"}`}
              onClick={() => setFemale(false)}
            >
              {gettext("I want to be a hero")}
            </button>
          </div>
          {/*
          <button type="button" className="button" onClick={skin.setPrevious}>
            previous skin color
          </button>
          */}
          <div className="buttons">
            <button type="button" className="button" onClick={skin.setNext}>
              {gettext("Change skin color")}
            </button>
            {/*
            <button type="button" className="button" onClick={hair.setPrevious}>
              previous hair
            </button>
            */}
            <button type="button" className="button" onClick={hair.setNext}>
              {gettext("Change hair style")}
            </button>
          </div>
          <button type="submit" className="button button--onboarding-save">
            {gettext("Save")}
          </button>
          <Saving>
            {() => (
              <div className="saving is-success">
                {/*
                XXX This isn't rendered at all because <Menu> immediately
                stops ONBOARDING as soon as equippedItems is set.

                The task of welcoming the user is handled by WELCOME.
                */}
                <div className="saving-content welcome">
                  <h1>{gettext("Welcome")}</h1>
                  <button type="button" className="button" onClick={() => {}}>
                    {gettext("Explore the city now!")}
                  </button>
                </div>
              </div>
            )}
          </Saving>
        </form>
        <div className="profile__hero-container">
          <div className="profile__hero">
            <Hero
              className="hero is-onboarding"
              assetGroup={assetGroup}
              items={items}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
