import { useEffect } from "react"
import withScrolling from "react-dnd-scrolling"

import {
  FMWDndProvider,
  useDragWithEmptyPreview as useDrag,
  useDrop,
} from "../dnd.js"
import { gettext, interpolate } from "../i18n.js"
import { clamp, logger, timestamp } from "../utils.js"

import { reducedExercise, useDispatch } from "./base.js"

import { Fullscreen } from "../fullscreen.js"

const ScrollingComponent = withScrolling("div")

function createItem(item) {
  return { ...item, id: ++createItem.__id }
}
createItem.__id = 0

function fillUpTo(state, type, count) {
  for (let i = state[type].length; i < count; ++i) {
    const item = createItem({ type, name: "", amount: "" })
    state.items[item.id] = item
    state[type].push(item.id)
  }
}

const START = "START"
const SET_ITEM_FIELDS = "SET_ITEM_FIELDS"
const SET_COMMENTARY = "SET_COMMENTARY"
const USE_SUGGESTION = "USE_SUGGESTION"

const INCOME = "income"
const FIXED_EXPENSES = "fixed"
const VARIABLE_EXPENSES = "variable"

const SUGGESTEDITEM_TYPE = "SuggestedItem"

function initialStateFromProps(props) {
  const items = props.items.map((data) => createItem(data))
  const state = {
    items: Object.fromEntries(items.map((item) => [item.id, item])),
    [INCOME]: items
      .filter((item) => item.isPrepopulated && item.type === INCOME)
      .map((item) => item.id),
    [FIXED_EXPENSES]: items
      .filter((item) => item.isPrepopulated && item.type === FIXED_EXPENSES)
      .map((item) => item.id),
    [VARIABLE_EXPENSES]: items
      .filter((item) => item.isPrepopulated && item.type === VARIABLE_EXPENSES)
      .map((item) => item.id),
    suggestions: items.filter((item) => !item.isPrepopulated),
    requireCommentary: props.requireCommentary,
    commentaryQuestion: props.commentaryQuestion,
    commentary: "",
    startedExerciseAt: timestamp(),
  }

  fillUpTo(state, INCOME, props.lines)
  fillUpTo(state, FIXED_EXPENSES, props.lines)
  fillUpTo(state, VARIABLE_EXPENSES, props.lines)

  state.prepopulatedExpenses = items
    .filter((item) => [FIXED_EXPENSES, VARIABLE_EXPENSES].includes(item.type))
    .reduce((a, b) => a + (b.amount || 0), 0)

  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case START:
      return {
        ...state,
        startedInteractiveAt: timestamp(),
      }

    case SET_ITEM_FIELDS: {
      const item = {
        ...state.items[action.id],
        ...action.fields,
      }

      return {
        ...state,
        items: {
          ...state.items,
          [item.id]: item,
        },
      }
    }

    case SET_COMMENTARY:
      return {
        ...state,
        commentary: action.commentary,
      }

    case USE_SUGGESTION:
      return {
        ...state,
        suggestions: state.suggestions.map((item) =>
          action.id === item.id
            ? {
                ...item,
                isUsed: true,
              }
            : item,
        ),
      }

    default:
      throw new Error(`Unknown action ${action}`)
  }
}

const filterItems = (items) =>
  items
    .filter((item) => item.amount)
    .map((item) => ({ name: item.name, amount: item.amount }))

const stateToSaveState = (state) => ({
  items: {
    [INCOME]: filterItems(itemsOfType(state, INCOME)),
    [FIXED_EXPENSES]: filterItems(itemsOfType(state, FIXED_EXPENSES)),
    [VARIABLE_EXPENSES]: filterItems(itemsOfType(state, VARIABLE_EXPENSES)),
  },
  commentaryQuestion: state.commentaryQuestion,
  commentary: state.commentary,
  durationInteractive: timestamp() - state.startedInteractiveAt,
  durationExercise: timestamp() - state.startedExerciseAt,
})

const itemsOfType = (state, type) => state[type].map((id) => state.items[id])
const typeTotal = (items) => items.reduce((a, b) => a + (b.amount || 0), 0)

function Table(props) {
  return (
    <table className={`a-calculation__table ${props.type}`}>
      <caption className="a-calculation__caption">{props.caption}</caption>
      <thead>
        <tr>
          <th>{gettext("Name")}</th>
          <th>{gettext("Amount")}</th>
        </tr>
      </thead>
      {props.totalLabel ? (
        <tfoot>
          <tr>
            <th className="a-calculation__total-name">{props.totalLabel}</th>
            <th className="a-calculation__total-amount">
              <input
                type="number"
                step="1"
                value={props.totalAmount}
                disabled
              />
            </th>
          </tr>
        </tfoot>
      ) : null}
      <tbody>
        {props.items.map((item) => (
          <TableLine key={item.id} item={item} />
        ))}
      </tbody>
    </table>
  )
}

const itemFieldValueSetter = (dispatch, item) => (fields) =>
  dispatch({
    type: SET_ITEM_FIELDS,
    id: item.id,
    fields,
  })

function TableLine({ item }) {
  const dispatch = useDispatch()
  const setter = itemFieldValueSetter(dispatch, item)

  const [{ isOver }, drop] = useDrop({
    accept: SUGGESTEDITEM_TYPE,
    collect: (monitor) => ({ isOver: monitor.isOver() }),
    drop: (item, _monitor) => {
      dispatch({ type: USE_SUGGESTION, id: item.id })
      setter({ name: item.name, amount: item.amount })
    },
  })

  // collectedProps && window.console.log({item, collectedProps})

  return (
    <tr ref={drop} className={`a-calculation__line ${isOver ? "is-over" : ""}`}>
      <td>
        <input
          type="text"
          value={item.name}
          maxLength={40}
          onChange={(e) => setter({ name: e.target.value })}
          disabled={item.disabled}
        />
      </td>
      <td className="a-calculation__currency">
        <input
          type="number"
          step="1"
          value={Number.isNaN(item.amount) ? "" : item.amount}
          onChange={(e) =>
            setter({
              amount: clamp(
                0,
                Number.parseInt(e.target.value, 10) || 0,
                999999,
              ),
            })
          }
          disabled={item.disabled}
        />
      </td>
    </tr>
  )
}

function SuggestedItem({ id, name, amount, isUsed }) {
  amount = Number.parseInt(amount, 10) || ""

  const [_collectedProps, drag] = useDrag({
    type: SUGGESTEDITEM_TYPE,
    item: {
      id,
      name,
      amount,
    },
  })

  return (
    <div
      className={`a-calculation__predefineditem ${isUsed ? "is-used" : ""}`}
      ref={drag}
    >
      {name} <small>CHF {amount}</small>
    </div>
  )
}

const SuggestedItemPreview = ({ item, translate }) => (
  <div
    style={{
      position: "absolute",
      transform: translate,
      margin: "-20px 0 0 -20px",
      opacity: "0.7",
    }}
  >
    <div className="a-calculation__predefineditem">
      {item.name} <small>CHF {item.amount}</small>
    </div>
  </div>
)

function Commentary({ state }) {
  const dispatch = useDispatch()

  if (!state.requireCommentary) return null

  return (
    <div className="a-text__question">
      <label htmlFor="commentary">{state.commentaryQuestion}</label>
      <div className="grow-wrap" data-replicated-value={state.commentary}>
        <textarea
          id="commentary"
          value={state.commentary}
          onChange={(e) =>
            dispatch({
              type: SET_COMMENTARY,
              commentary: e.target.value,
            })
          }
        />
      </div>
    </div>
  )
}

export const CalculateIncomeAndExpenses = reducedExercise({
  persisted: true,
  reducer: logger("CalculateIncomeAndExpenses: ", reducer),
  initialStateFromProps,
  Implementation: ({ state, setSaveState, isCurrent }) => {
    const incomeItems = itemsOfType(state, INCOME)
    const fixedExpensesItems = itemsOfType(state, FIXED_EXPENSES)
    const variableExpensesItems = itemsOfType(state, VARIABLE_EXPENSES)

    const income = typeTotal(incomeItems)
    const fixedExpenses = typeTotal(fixedExpensesItems)
    const variableExpenses = typeTotal(variableExpensesItems)

    window.console.log(state)

    const isValid = true // FIXME

    const dispatch = useDispatch()
    useEffect(() => {
      if (isCurrent && !state.startedInteractiveAt) {
        dispatch({ type: START })
      }
    })

    const saveState = () => ({
      ...stateToSaveState(state),
      summary: {
        [INCOME]: income,
        [FIXED_EXPENSES]: fixedExpenses,
        [VARIABLE_EXPENSES]: variableExpenses,
        items: [
          { name: gettext("Income"), amount: income },
          { name: gettext("Fixed expenses"), amount: -fixedExpenses },
          { name: gettext("Variable expenses"), amount: -variableExpenses },
        ],
        totalLabel: gettext("Total"),
        totalAmount: income - fixedExpenses - variableExpenses,
      },
    })

    return (
      <Fullscreen allowFullscreen={!state.finishedAt}>
        <FMWDndProvider Preview={SuggestedItemPreview}>
          <ScrollingComponent className="exercise__scrolling-container">
            {state.suggestions.length ? (
              <div className="a-calculation__predefining">
                <strong>
                  {gettext("Drag the following suggestions into a table:")}
                </strong>
                <div className="a-calculation__predefineditems">
                  {state.suggestions.map((item) => (
                    <SuggestedItem key={item.id} {...item} />
                  ))}
                </div>
              </div>
            ) : null}
            <div className="a-calculation">
              <Table
                type={INCOME}
                items={incomeItems}
                totalLabel={gettext("Total")}
                totalAmount={income}
                caption={gettext("Income")}
              />
              <Table
                type={FIXED_EXPENSES}
                items={fixedExpensesItems}
                totalLabel={gettext("Total")}
                totalAmount={fixedExpenses}
                caption={gettext("Fixed expenses")}
              />
              <Table
                type={VARIABLE_EXPENSES}
                items={variableExpensesItems}
                totalLabel={gettext("Total")}
                totalAmount={variableExpenses}
                caption={gettext("Variable expenses")}
              />
              <Table
                type="summary"
                items={[
                  {
                    id: -1,
                    name: gettext("Income"),
                    amount: income,
                    disabled: true,
                  },
                  {
                    id: -2,
                    name: gettext("Fixed expenses"),
                    amount: -fixedExpenses,
                    disabled: true,
                  },
                  {
                    id: -3,
                    name: gettext("Variable expenses"),
                    amount: -variableExpenses,
                    disabled: true,
                  },
                ]}
                totalLabel={gettext("Total")}
                totalAmount={income - fixedExpenses - variableExpenses}
                caption={gettext("Summary")}
              />
            </div>
            <Commentary state={state} />
            <button
              type="button"
              className="button button--save"
              disabled={!isValid}
              onClick={() => {
                if (document.fullscreenElement) {
                  document.exitFullscreen()
                }
                setSaveState(saveState())
              }}
            >
              {gettext("Save")}
            </button>
          </ScrollingComponent>
        </FMWDndProvider>
      </Fullscreen>
    )
  },
})

function SavingsTable({ state, monthlyBalance }) {
  const dispatch = useDispatch()

  const { savedAlready, targetedMonths, targets } = state

  const targetTotal = typeTotal(targets)

  const delta = savedAlready + targetedMonths * monthlyBalance - targetTotal
  const earlier = Math.floor(delta / monthlyBalance)

  let message = null

  if (Number.isNaN(earlier)) {
    /* nothing */
  } else if (delta < 0) {
    message = gettext(
      "You have to be more strict with your expenses or wait longer.",
    )
  } else if (earlier <= 2) {
    message = gettext("Super! You have achieved a balanced budget.")
  } else {
    message = interpolate(
      gettext(
        "Super! You even achieve your targets %(months)s months earlier with this budget.",
      ),
      { months: earlier },
    )
  }

  return (
    <table className="a-calculation__table savings">
      <caption className="a-calculation__caption">
        {gettext("Savings target")}
      </caption>
      <tbody>
        <tr className="a-calculation__line is-savings-target">
          <th>{gettext("Targets")}</th>
          <th>{gettext("Amount")}</th>
        </tr>
        {targets.map((target, index) => {
          return (
            <tr key={index} className="a-calculation__line is-savings-target">
              <td>
                <input
                  type="text"
                  maxLength={40}
                  value={target.name}
                  onChange={(e) =>
                    dispatch({
                      type: TARGETS,
                      index,
                      target: { ...target, name: e.target.value },
                    })
                  }
                />
              </td>
              <td className="a-calculation__currency">
                <input
                  type="number"
                  step="1"
                  value={target.amount}
                  onChange={(e) =>
                    dispatch({
                      type: TARGETS,
                      index,
                      target: {
                        ...target,
                        amount: clamp(
                          0,
                          Number.parseInt(e.target.value, 10) || 0,
                          999999,
                        ),
                      },
                    })
                  }
                />
              </td>
            </tr>
          )
        })}
        <tr className="a-calculation__line is-savings-target">
          <td>{gettext("Total")}</td>
          <td className="a-calculation__currency">
            <input type="number" step="1" value={targetTotal} disabled />
          </td>
        </tr>
        <tr>
          <td colSpan="2">&nbsp;</td>
        </tr>
        <tr className="a-calculation__line is-savings-account">
          <th>{gettext("I have saved this much already")}</th>
          <td className="a-calculation__currency">
            <input
              type="number"
              step="1"
              value={savedAlready}
              onChange={(e) =>
                dispatch({
                  type: SAVINGS_ACCOUNT,
                  savedAlready: clamp(
                    0,
                    Number.parseInt(e.target.value, 10),
                    999999,
                  ),
                })
              }
            />
          </td>
        </tr>
        <tr className="a-calculation__line is-months">
          <th>{gettext("I want to save this many months")}</th>
          <td>
            <input
              type="number"
              step="1"
              value={targetedMonths}
              onChange={(e) =>
                dispatch({
                  type: UPDATE_SAVINGS,
                  data: {
                    targetedMonths: clamp(
                      0,
                      Number.parseInt(e.target.value, 10),
                      999,
                    ),
                  },
                })
              }
            />
          </td>
        </tr>
        <tr className="a-calculation__line is-monthly-balance">
          <td>{gettext("I can save this much")}</td>
          <td className="a-calculation__currency">
            <input
              type="number"
              step="1"
              value={monthlyBalance * targetedMonths}
              disabled
            />
          </td>
        </tr>
        <tr>
          <td colSpan="2">&nbsp;</td>
        </tr>
        <tr className="a-calculation__line is-balance">
          <th>{delta < 0 ? gettext("Missing amount") : gettext("Surplus")}</th>
          <td className="a-calculation__currency">
            <input type="number" step="1" value={delta} disabled />
          </td>
        </tr>
        <tr>
          <td colSpan="2">{message}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th className="a-calculation__total-name">&nbsp;</th>
          <th className="a-calculation__total-amount">&nbsp;</th>
        </tr>
      </tfoot>
    </table>
  )
}

const UPDATE_SAVINGS = "UPDATE_SAVINGS"
const SAVINGS_ACCOUNT = "SAVINGS_ACCOUNT"
const TARGETS = "TARGETS"

export const CalculateBudgetWithSavingsTarget = reducedExercise({
  persisted: true,
  reducer: logger("CalculateBudgetWithSavingsTarget: ", (state, action) => {
    switch (action.type) {
      case SAVINGS_ACCOUNT:
        return {
          ...state,
          savedAlready: action.savedAlready,
        }

      case TARGETS: {
        const targets = [...state.targets]
        targets[action.index] = action.target

        return { ...state, targets }
      }

      case UPDATE_SAVINGS:
        return { ...state, ...action.data }

      default:
        return reducer(state, action)
    }
  }),
  initialStateFromProps: (props) => {
    const state = initialStateFromProps(props)
    return {
      savedAlready: "",
      targets: [
        { name: "", amount: "" },
        { name: "", amount: "" },
      ],
      targetedMonths: 24,
      ...state,
    }
  },
  Implementation: ({ state, setSaveState, isCurrent }) => {
    const incomeItems = itemsOfType(state, INCOME)
    const fixedExpensesItems = itemsOfType(state, FIXED_EXPENSES)
    const variableExpensesItems = itemsOfType(state, VARIABLE_EXPENSES)

    const income = typeTotal(incomeItems)
    const fixedExpenses = typeTotal(fixedExpensesItems)
    const variableExpenses = typeTotal(variableExpensesItems)

    window.console.log(state)

    const isValid = true // FIXME

    const dispatch = useDispatch()
    useEffect(() => {
      if (isCurrent && !state.startedInteractiveAt) {
        dispatch({ type: START })
      }
    })

    const saveState = () => ({
      ...stateToSaveState(state),
      savedAlready: state.savedAlready,
      targets: state.targets,
      targetedMonths: state.targetedMonths,
      // FIXME delta?
      summary: {
        [INCOME]: income,
        [FIXED_EXPENSES]: fixedExpenses,
        [VARIABLE_EXPENSES]: variableExpenses,
        items: [
          { name: gettext("Income"), amount: income },
          { name: gettext("Fixed expenses"), amount: -fixedExpenses },
          { name: gettext("Variable expenses"), amount: -variableExpenses },
        ],
        totalLabel: gettext("Total"),
        totalAmount: monthlyBalance,
      },
    })

    const monthlyBalance = income - fixedExpenses - variableExpenses

    return (
      <Fullscreen allowFullscreen={!state.finishedAt}>
        <FMWDndProvider Preview={SuggestedItemPreview}>
          <ScrollingComponent className="exercise__scrolling-container">
            {state.suggestions.length ? (
              <div className="a-calculation__predefining">
                <strong>
                  {gettext("Drag the following suggestions into a table:")}
                </strong>
                <div className="a-calculation__predefineditems">
                  {state.suggestions.map((item) => (
                    <SuggestedItem key={item.id} {...item} />
                  ))}
                </div>
              </div>
            ) : null}
            <div className="a-calculation">
              <Table
                type={INCOME}
                items={incomeItems}
                totalLabel={gettext("Total")}
                totalAmount={income}
                caption={gettext("Income")}
              />
              <Table
                type={FIXED_EXPENSES}
                items={fixedExpensesItems}
                totalLabel={gettext("Total")}
                totalAmount={fixedExpenses}
                caption={gettext("Fixed expenses")}
              />
              <Table
                type={VARIABLE_EXPENSES}
                items={variableExpensesItems}
                totalLabel={gettext("Total")}
                totalAmount={variableExpenses}
                caption={gettext("Variable expenses")}
              />
              <Table
                type="summary"
                items={[
                  {
                    id: -1,
                    name: gettext("Income"),
                    amount: income,
                    disabled: true,
                  },
                  {
                    id: -2,
                    name: gettext("Fixed expenses"),
                    amount: -fixedExpenses,
                    disabled: true,
                  },
                  {
                    id: -3,
                    name: gettext("Variable expenses"),
                    amount: -variableExpenses,
                    disabled: true,
                  },
                ]}
                totalLabel={gettext("Total")}
                totalAmount={monthlyBalance}
                caption={gettext("Monthly balance")}
              />
              <SavingsTable state={state} monthlyBalance={monthlyBalance} />
            </div>
            <Commentary state={state} />
            <button
              type="button"
              className="button button--save"
              disabled={!isValid}
              onClick={() => {
                if (document.fullscreenElement) {
                  document.exitFullscreen()
                }
                setSaveState(saveState())
              }}
            >
              {gettext("Save")}
            </button>
          </ScrollingComponent>
        </FMWDndProvider>
      </Fullscreen>
    )
  },
})

export const CalculateSavingsPotential = reducedExercise({
  persisted: true,
  reducer,
  initialStateFromProps: (props) => {
    props.items.forEach((item) => {
      item.isPrepopulated = true
    })
    return initialStateFromProps(props)
  },
  Implementation: ({ state, setSaveState, isCurrent }) => {
    const fixedExpensesItems = itemsOfType(state, FIXED_EXPENSES)
    const variableExpensesItems = itemsOfType(state, VARIABLE_EXPENSES)

    window.console.log(state)

    const isValid = true // FIXME

    const fixedExpenses = typeTotal(fixedExpensesItems)
    const variableExpenses = typeTotal(variableExpensesItems)
    const newTotal = fixedExpenses + variableExpenses

    const dispatch = useDispatch()
    useEffect(() => {
      if (isCurrent && !state.startedInteractiveAt) {
        dispatch({ type: START })
      }
    })

    const saveState = () => ({
      ...stateToSaveState(state),
      summary: {
        [FIXED_EXPENSES]: fixedExpenses,
        [VARIABLE_EXPENSES]: variableExpenses,
        initial: state.prepopulatedExpenses,
        newTotal,
        items: [
          {
            name: gettext("Initial expenses"),
            amount: state.prepopulatedExpenses,
          },
          { name: gettext("New expenses"), amount: newTotal },
        ],
        totalLabel: gettext("Savings"),
        totalAmount: state.prepopulatedExpenses - newTotal,
      },
    })

    return (
      <Fullscreen allowFullscreen={!state.finishedAt}>
        <FMWDndProvider Preview={SuggestedItemPreview}>
          <ScrollingComponent className="exercise__scrolling-container">
            <div className="a-calculation">
              <Table
                type={FIXED_EXPENSES}
                items={fixedExpensesItems}
                totalLabel={gettext("Total")}
                totalAmount={fixedExpenses}
                caption={gettext("Fixed expenses")}
              />
              <Table
                type={VARIABLE_EXPENSES}
                items={variableExpensesItems}
                totalLabel={gettext("Total")}
                totalAmount={variableExpenses}
                caption={gettext("Variable expenses")}
              />
              <Table
                type="summary"
                items={[
                  {
                    id: -1,
                    name: gettext("Initial expenses"),
                    amount: state.prepopulatedExpenses,
                    disabled: true,
                  },
                  {
                    id: -2,
                    name: gettext("New expenses"),
                    amount: newTotal,
                    disabled: true,
                  },
                ]}
                totalLabel={gettext("Savings")}
                totalAmount={state.prepopulatedExpenses - newTotal}
                caption={gettext("Summary")}
              />
            </div>
            <Commentary state={state} />
            <button
              type="button"
              className="button button--save"
              disabled={!isValid}
              onClick={() => {
                if (document.fullscreenElement) {
                  document.exitFullscreen()
                }
                setSaveState(saveState())
              }}
            >
              {gettext("Save")}
            </button>
          </ScrollingComponent>
        </FMWDndProvider>
      </Fullscreen>
    )
  },
})
