import { useEffect, useState } from "react"

import shuffle from "@anttikissa/fisher-yates-shuffle"

import { hero } from "./assets.js"
import { fetchJSON } from "./fetch.js"
import { Hero } from "./heroes.js"
import { gettext } from "./i18n.js"
import { useSettings } from "./utils.js"

export const ClassBook = ({ handleClose }) => {
  const { urls } = useSettings()
  const [klass, setKlass] = useState(null)
  useEffect(() => {
    const _fetch = async () => {
      const data = await fetchJSON(urls.class)
      setKlass((current) => current || data)
    }
    _fetch()
  }, [urls])

  return (
    <div className="overlay is-profile is-classmates">
      <button
        type="button"
        className="button button--profile"
        onClick={handleClose}
      >
        &lt; {gettext("Back to the apps")}
      </button>

      <div className="classmates-container apps__container">
        <div className="classmates">
          <h1 className="classmates__title">{klass?.name}</h1>
          <div className="classmates__book">
            {klass?.students && <Students students={klass.students} />}
          </div>
          {klass?.error && (
            <p className="error" style={{ color: "red", fontSize: "2rem" }}>
              {klass.error}
            </p>
          )}
          {klass?.students?.length === 0 ? (
            <p className="error" style={{ color: "red", fontSize: "2rem" }}>
              {gettext("No students in the class")}
            </p>
          ) : null}
        </div>
      </div>
    </div>
  )
}

const Students = ({ students }) =>
  shuffle(students).map((u, idx) => (
    <section
      className="classmates__classmate profile__hero-container"
      key={idx}
    >
      <div className="profile__hero">
        <Hero
          className="hero"
          assetGroup={(hero[u.sex] || hero.female).perspective}
          items={u.equippedItems}
          heroInfo={u}
        />
      </div>
    </section>
  ))
