import shuffle from "@anttikissa/fisher-yates-shuffle"
import { useEffect } from "react"

import { logger, sleep, timestamp } from "../utils.js"

import { Timer } from "./Timer.js"
import { reducedExercise, useDispatch } from "./base.js"

import { Fullscreen } from "../fullscreen.js"

const START = "START"
const FLIP = "FLIP"
const FOUND = "FOUND"
const TIME_UP = "TIME_UP"

function initialStateFromProps({ pairs, timeLimit }) {
  const cards = shuffle(
    pairs.flatMap((pair) => [
      {
        image: pair.firstImage,
        cardId: `${pair.id}-first`,
        pairId: pair.id,
      },
      {
        image: pair.secondImage,
        cardId: `${pair.id}-second`,
        pairId: pair.id,
      },
    ]),
  )

  return {
    cards,
    timeLimit,
    first: null,
    second: null,
    found: [],
    rounds: 0,
    startedInteractiveAt: null,
    startedExerciseAt: timestamp(),
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case START:
      return {
        ...state,
        startedInteractiveAt: timestamp(),
      }

    case TIME_UP:
      // We may have finished already. Return state unchanged (object identity!)
      return state.finishedAt
        ? state
        : {
            ...state,
            blockFlips: true,
            finishedAt: timestamp(),
          }

    case FLIP:
      return {
        ...state,
        first: action.first,
        second: action.second,
        blockFlips: action.blockFlips,
        rounds: state.rounds + (action.newRound ? 1 : 0),
      }

    case FOUND:
      state = {
        ...state,
        found: [...state.found, action.first.cardId, action.second.cardId],
        first: null,
        second: null,
      }
      if (state.found.length === state.cards.length) {
        state.blockFlips = true
        state.finishedAt = timestamp()
      }
      return state

    default:
      throw new Error(`Unknown action ${action}`)
  }
}

function showAsFlipped(state, cardId) {
  return (
    state.found.includes(cardId) ||
    (state.first && state.first.cardId === cardId) ||
    (state.second && state.second.cardId === cardId)
  )
}

function saveState(state) {
  return {
    durationInteractive: timestamp() - state.startedInteractiveAt,
    durationExercise: timestamp() - state.startedExerciseAt,
    timeLimit: state.timeLimit,
    found: state.found.length,
    cards: state.cards.length,
    rounds: state.rounds,
  }
}

export function MemoryImpl({ ownProps, state, setSaveState, isCurrent }) {
  const { exercise } = ownProps

  const dispatch = useDispatch()

  console.log("In MemoryImpl")

  useEffect(() => {
    if (isCurrent && !state.startedInteractiveAt) {
      dispatch({ type: START })
    }
  })

  useEffect(() => {
    if (state.startedInteractiveAt && state.timeLimit) {
      const timeout = setTimeout(() => {
        dispatch({ type: TIME_UP })
      }, state.timeLimit * 1000)
      return () => {
        console.log("Clearing the TIME_UP timeout")
        clearTimeout(timeout)
      }
    }
  }, [dispatch, state.startedInteractiveAt, state.timeLimit])

  useEffect(() => {
    if (state.finishedAt) {
      setSaveState(saveState(state))
    }
  }, [state, setSaveState])

  return (
    <Fullscreen allowFullscreen={!state.finishedAt}>
      <div className="a-memory">
        <div className="a-memory__cards">
          {state.cards.map((card) => (
            <Card
              key={card.cardId}
              backSideImage={exercise.backSideImage}
              card={card}
              isFlipped={showAsFlipped(state, card.cardId)}
              onFlip={async () => {
                if (state.blockFlips) return

                if (!state.first) {
                  dispatch({
                    type: FLIP,
                    first: card,
                    second: null,
                    newRound: true,
                  })
                } else if (state.first.cardId === card.cardId) {
                  // Ignore clicks on same card
                  return
                } else if (state.first.pairId !== card.pairId) {
                  dispatch({
                    type: FLIP,
                    first: state.first,
                    second: card,
                    blockFlips: true,
                  })
                  await sleep(1000)
                  dispatch({
                    type: FLIP,
                    first: null,
                    second: null,
                    blockFlips: true,
                  })
                  await sleep(250)
                  dispatch({ type: FLIP, first: null, second: null })
                } else {
                  dispatch({ type: FOUND, first: state.first, second: card })
                }
              }}
            />
          ))}
        </div>
        {state.timeLimit ? (
          <Timer
            key={state.startedInteractiveAt}
            timeLimit={state.timeLimit}
            isRunning={state.startedInteractiveAt && !state.finishedAt}
          />
        ) : null}
      </div>
    </Fullscreen>
  )
}

function Card({ backSideImage, card, isFlipped, onFlip }) {
  return (
    <div
      className={`a-memory__card ${isFlipped ? "show-front" : "show-back"}`}
      onClick={onFlip}
    >
      <div
        className="a-memory__face is-front"
        style={{ backgroundImage: `url(${card.image})` }}
      />
      {backSideImage ? (
        <div
          className="a-memory__face is-back"
          style={{ backgroundImage: `url(${backSideImage})` }}
        />
      ) : (
        <div className="a-memory__face is-back has-no-custom-image" />
      )}
    </div>
  )
}

export const Memory = reducedExercise({
  reducer: logger("Memory: ", reducer),
  initialStateFromProps,
  Implementation: MemoryImpl,
})
