export const hero = {
  female: { iso: {}, perspective: {} },
  male: { iso: {}, perspective: {} },
  overpaints: {},
}

hero.female.iso.Accessoire_01 = new URL(
  "./img/heroes/Hero_Female_Iso-Accessoire_01.png",
  import.meta.url,
)
hero.female.iso.Accessoire_02 = new URL(
  "./img/heroes/Hero_Female_Iso-Accessoire_02.png",
  import.meta.url,
)
hero.female.iso.Accessoire_03 = new URL(
  "./img/heroes/Hero_Female_Iso-Accessoire_03.png",
  import.meta.url,
)
hero.female.iso.Accessoire_04 = new URL(
  "./img/heroes/Hero_Female_Iso-Accessoire_04.png",
  import.meta.url,
)
hero.female.iso.Accessoire_05 = new URL(
  "./img/heroes/Hero_Female_Iso-Accessoire_05.png",
  import.meta.url,
)
hero.female.iso.Accessoire_06 = new URL(
  "./img/heroes/Hero_Female_Iso-Accessoire_06.png",
  import.meta.url,
)
hero.female.iso.Accessoire_07 = new URL(
  "./img/heroes/Hero_Female_Iso-Accessoire_07.png",
  import.meta.url,
)
hero.female.iso.Accessoire_08 = new URL(
  "./img/heroes/Hero_Female_Iso-Accessoire_08.png",
  import.meta.url,
)
hero.female.iso.Accessoire_09 = new URL(
  "./img/heroes/Hero_Female_Iso-Accessoire_09.png",
  import.meta.url,
)
hero.female.iso.Accessoire_10 = new URL(
  "./img/heroes/Hero_Female_Iso-Accessoire_10.png",
  import.meta.url,
)
hero.female.iso.Cape_01 = new URL(
  "./img/heroes/Hero_Female_Iso-Cape_01.png",
  import.meta.url,
)
hero.female.iso.Cape_02 = new URL(
  "./img/heroes/Hero_Female_Iso-Cape_02.png",
  import.meta.url,
)
hero.female.iso.Cape_03 = new URL(
  "./img/heroes/Hero_Female_Iso-Cape_03.png",
  import.meta.url,
)
hero.female.iso.Cape_04 = new URL(
  "./img/heroes/Hero_Female_Iso-Cape_04.png",
  import.meta.url,
)
hero.female.iso.Cape_05 = new URL(
  "./img/heroes/Hero_Female_Iso-Cape_05.png",
  import.meta.url,
)
hero.female.iso.Cape_06 = new URL(
  "./img/heroes/Hero_Female_Iso-Cape_06.png",
  import.meta.url,
)
hero.female.iso.Cape_07 = new URL(
  "./img/heroes/Hero_Female_Iso-Cape_07.png",
  import.meta.url,
)
hero.female.iso.Cape_08 = new URL(
  "./img/heroes/Hero_Female_Iso-Cape_08.png",
  import.meta.url,
)
hero.female.iso.Cape_09 = new URL(
  "./img/heroes/Hero_Female_Iso-Cape_09.png",
  import.meta.url,
)
hero.female.iso.Cape_10 = new URL(
  "./img/heroes/Hero_Female_Iso-Cape_10.png",
  import.meta.url,
)
hero.female.iso.Hair_01_Black = new URL(
  "./img/heroes/Hero_Female_Iso-Hair_01_Black.png",
  import.meta.url,
)
hero.female.iso.Hair_01_Blonde = new URL(
  "./img/heroes/Hero_Female_Iso-Hair_01_Blonde.png",
  import.meta.url,
)
hero.female.iso.Hair_01_Blue = new URL(
  "./img/heroes/Hero_Female_Iso-Hair_01_Blue.png",
  import.meta.url,
)
hero.female.iso.Hair_01_Brown = new URL(
  "./img/heroes/Hero_Female_Iso-Hair_01_Brown.png",
  import.meta.url,
)
hero.female.iso.Hair_01_Red = new URL(
  "./img/heroes/Hero_Female_Iso-Hair_01_Red.png",
  import.meta.url,
)
hero.female.iso.Hair_02_Black = new URL(
  "./img/heroes/Hero_Female_Iso-Hair_02_Black.png",
  import.meta.url,
)
hero.female.iso.Hair_02_Blonde = new URL(
  "./img/heroes/Hero_Female_Iso-Hair_02_Blonde.png",
  import.meta.url,
)
hero.female.iso.Hair_02_Blue = new URL(
  "./img/heroes/Hero_Female_Iso-Hair_02_Blue.png",
  import.meta.url,
)
hero.female.iso.Hair_02_Brown = new URL(
  "./img/heroes/Hero_Female_Iso-Hair_02_Brown.png",
  import.meta.url,
)
hero.female.iso.Hair_02_Red = new URL(
  "./img/heroes/Hero_Female_Iso-Hair_02_Red.png",
  import.meta.url,
)
hero.female.iso.Hair_03_Black = new URL(
  "./img/heroes/Hero_Female_Iso-Hair_03_Black.png",
  import.meta.url,
)
hero.female.iso.Hair_03_Blonde = new URL(
  "./img/heroes/Hero_Female_Iso-Hair_03_Blonde.png",
  import.meta.url,
)
hero.female.iso.Hair_03_Blue = new URL(
  "./img/heroes/Hero_Female_Iso-Hair_03_Blue.png",
  import.meta.url,
)
hero.female.iso.Hair_03_Brown = new URL(
  "./img/heroes/Hero_Female_Iso-Hair_03_Brown.png",
  import.meta.url,
)
hero.female.iso.Hair_03_Red = new URL(
  "./img/heroes/Hero_Female_Iso-Hair_03_Red.png",
  import.meta.url,
)
hero.female.iso.Hair_04_Black = new URL(
  "./img/heroes/Hero_Female_Iso-Hair_04_Black.png",
  import.meta.url,
)
hero.female.iso.Hair_04_Blonde = new URL(
  "./img/heroes/Hero_Female_Iso-Hair_04_Blonde.png",
  import.meta.url,
)
hero.female.iso.Hair_04_Blue = new URL(
  "./img/heroes/Hero_Female_Iso-Hair_04_Blue.png",
  import.meta.url,
)
hero.female.iso.Hair_04_Brown = new URL(
  "./img/heroes/Hero_Female_Iso-Hair_04_Brown.png",
  import.meta.url,
)
hero.female.iso.Hair_04_Red = new URL(
  "./img/heroes/Hero_Female_Iso-Hair_04_Red.png",
  import.meta.url,
)
hero.female.iso.Hair_05_Black = new URL(
  "./img/heroes/Hero_Female_Iso-Hair_05_Black.png",
  import.meta.url,
)
hero.female.iso.Hair_05_Blonde = new URL(
  "./img/heroes/Hero_Female_Iso-Hair_05_Blonde.png",
  import.meta.url,
)
hero.female.iso.Hair_05_Blue = new URL(
  "./img/heroes/Hero_Female_Iso-Hair_05_Blue.png",
  import.meta.url,
)
hero.female.iso.Hair_05_Brown = new URL(
  "./img/heroes/Hero_Female_Iso-Hair_05_Brown.png",
  import.meta.url,
)
hero.female.iso.Hair_05_Red = new URL(
  "./img/heroes/Hero_Female_Iso-Hair_05_Red.png",
  import.meta.url,
)
hero.female.iso.Lifestyle_01 = new URL(
  "./img/heroes/Hero_Female_Iso-Lifestyle_01.png",
  import.meta.url,
)
hero.female.iso.Lifestyle_02 = new URL(
  "./img/heroes/Hero_Female_Iso-Lifestyle_02.png",
  import.meta.url,
)
hero.female.iso.Lifestyle_03 = new URL(
  "./img/heroes/Hero_Female_Iso-Lifestyle_03.png",
  import.meta.url,
)
hero.female.iso.Lifestyle_04 = new URL(
  "./img/heroes/Hero_Female_Iso-Lifestyle_04.png",
  import.meta.url,
)
hero.female.iso.Lifestyle_05 = new URL(
  "./img/heroes/Hero_Female_Iso-Lifestyle_05.png",
  import.meta.url,
)
hero.female.iso.Lifestyle_06 = new URL(
  "./img/heroes/Hero_Female_Iso-Lifestyle_06.png",
  import.meta.url,
)
hero.female.iso.Lifestyle_07 = new URL(
  "./img/heroes/Hero_Female_Iso-Lifestyle_07.png",
  import.meta.url,
)
hero.female.iso.Lifestyle_08 = new URL(
  "./img/heroes/Hero_Female_Iso-Lifestyle_08.png",
  import.meta.url,
)
hero.female.iso.Lifestyle_09 = new URL(
  "./img/heroes/Hero_Female_Iso-Lifestyle_09.png",
  import.meta.url,
)
hero.female.iso.Lifestyle_10 = new URL(
  "./img/heroes/Hero_Female_Iso-Lifestyle_10.png",
  import.meta.url,
)
hero.female.iso.Mask_01 = new URL(
  "./img/heroes/Hero_Female_Iso-Mask_01.png",
  import.meta.url,
)
hero.female.iso.Mask_02 = new URL(
  "./img/heroes/Hero_Female_Iso-Mask_02.png",
  import.meta.url,
)
hero.female.iso.Mask_03 = new URL(
  "./img/heroes/Hero_Female_Iso-Mask_03.png",
  import.meta.url,
)
hero.female.iso.Mask_04 = new URL(
  "./img/heroes/Hero_Female_Iso-Mask_04.png",
  import.meta.url,
)
hero.female.iso.Mask_05 = new URL(
  "./img/heroes/Hero_Female_Iso-Mask_05.png",
  import.meta.url,
)
hero.female.iso.Mask_06 = new URL(
  "./img/heroes/Hero_Female_Iso-Mask_06.png",
  import.meta.url,
)
hero.female.iso.Mask_07 = new URL(
  "./img/heroes/Hero_Female_Iso-Mask_07.png",
  import.meta.url,
)
hero.female.iso.Mask_08 = new URL(
  "./img/heroes/Hero_Female_Iso-Mask_08.png",
  import.meta.url,
)
hero.female.iso.Mask_09 = new URL(
  "./img/heroes/Hero_Female_Iso-Mask_09.png",
  import.meta.url,
)
hero.female.iso.Mask_10 = new URL(
  "./img/heroes/Hero_Female_Iso-Mask_10.png",
  import.meta.url,
)
hero.female.iso.Shoes_00 = new URL(
  "./img/heroes/Hero_Female_Iso-Shoes_00.png",
  import.meta.url,
)
hero.female.iso.Shoes_01 = new URL(
  "./img/heroes/Hero_Female_Iso-Shoes_01.png",
  import.meta.url,
)
hero.female.iso.Shoes_02 = new URL(
  "./img/heroes/Hero_Female_Iso-Shoes_02.png",
  import.meta.url,
)
hero.female.iso.Shoes_03 = new URL(
  "./img/heroes/Hero_Female_Iso-Shoes_03.png",
  import.meta.url,
)
hero.female.iso.Shoes_04 = new URL(
  "./img/heroes/Hero_Female_Iso-Shoes_04.png",
  import.meta.url,
)
hero.female.iso.Shoes_05 = new URL(
  "./img/heroes/Hero_Female_Iso-Shoes_05.png",
  import.meta.url,
)
hero.female.iso.Shoes_06 = new URL(
  "./img/heroes/Hero_Female_Iso-Shoes_06.png",
  import.meta.url,
)
hero.female.iso.Shoes_07 = new URL(
  "./img/heroes/Hero_Female_Iso-Shoes_07.png",
  import.meta.url,
)
hero.female.iso.Shoes_08 = new URL(
  "./img/heroes/Hero_Female_Iso-Shoes_08.png",
  import.meta.url,
)
hero.female.iso.Shoes_09 = new URL(
  "./img/heroes/Hero_Female_Iso-Shoes_09.png",
  import.meta.url,
)
hero.female.iso.Shoes_10 = new URL(
  "./img/heroes/Hero_Female_Iso-Shoes_10.png",
  import.meta.url,
)
hero.female.iso.Skin_01 = new URL(
  "./img/heroes/Hero_Female_Iso-Skin_01.png",
  import.meta.url,
)
hero.female.iso.Skin_02 = new URL(
  "./img/heroes/Hero_Female_Iso-Skin_02.png",
  import.meta.url,
)
hero.female.iso.Skin_03 = new URL(
  "./img/heroes/Hero_Female_Iso-Skin_03.png",
  import.meta.url,
)
hero.female.iso.Skin_04 = new URL(
  "./img/heroes/Hero_Female_Iso-Skin_04.png",
  import.meta.url,
)
hero.female.iso.Suit_00 = new URL(
  "./img/heroes/Hero_Female_Iso-Suit_00.png",
  import.meta.url,
)
hero.female.iso.Suit_01 = new URL(
  "./img/heroes/Hero_Female_Iso-Suit_01.png",
  import.meta.url,
)
hero.female.iso.Suit_02 = new URL(
  "./img/heroes/Hero_Female_Iso-Suit_02.png",
  import.meta.url,
)
hero.female.iso.Suit_03 = new URL(
  "./img/heroes/Hero_Female_Iso-Suit_03.png",
  import.meta.url,
)
hero.female.iso.Suit_04 = new URL(
  "./img/heroes/Hero_Female_Iso-Suit_04.png",
  import.meta.url,
)
hero.female.iso.Suit_05 = new URL(
  "./img/heroes/Hero_Female_Iso-Suit_05.png",
  import.meta.url,
)
hero.female.iso.Suit_06 = new URL(
  "./img/heroes/Hero_Female_Iso-Suit_06.png",
  import.meta.url,
)
hero.female.iso.Suit_07 = new URL(
  "./img/heroes/Hero_Female_Iso-Suit_07.png",
  import.meta.url,
)
hero.female.iso.Suit_08 = new URL(
  "./img/heroes/Hero_Female_Iso-Suit_08.png",
  import.meta.url,
)
hero.female.iso.Suit_09 = new URL(
  "./img/heroes/Hero_Female_Iso-Suit_09.png",
  import.meta.url,
)
hero.female.iso.Suit_10 = new URL(
  "./img/heroes/Hero_Female_Iso-Suit_10.png",
  import.meta.url,
)
hero.female.iso.Accessoire = [
  "Accessoire_01",
  "Accessoire_02",
  "Accessoire_03",
  "Accessoire_04",
  "Accessoire_05",
  "Accessoire_06",
  "Accessoire_07",
  "Accessoire_08",
  "Accessoire_09",
  "Accessoire_10",
]
hero.female.iso.Cape = [
  "Cape_01",
  "Cape_02",
  "Cape_03",
  "Cape_04",
  "Cape_05",
  "Cape_06",
  "Cape_07",
  "Cape_08",
  "Cape_09",
  "Cape_10",
]
hero.female.iso.Hair = [
  "Hair_01_Black",
  "Hair_01_Blonde",
  "Hair_01_Blue",
  "Hair_01_Brown",
  "Hair_01_Red",
  "Hair_02_Black",
  "Hair_02_Blonde",
  "Hair_02_Blue",
  "Hair_02_Brown",
  "Hair_02_Red",
  "Hair_03_Black",
  "Hair_03_Blonde",
  "Hair_03_Blue",
  "Hair_03_Brown",
  "Hair_03_Red",
  "Hair_04_Black",
  "Hair_04_Blonde",
  "Hair_04_Blue",
  "Hair_04_Brown",
  "Hair_04_Red",
  "Hair_05_Black",
  "Hair_05_Blonde",
  "Hair_05_Blue",
  "Hair_05_Brown",
  "Hair_05_Red",
]
hero.female.iso.Lifestyle = [
  "Lifestyle_01",
  "Lifestyle_02",
  "Lifestyle_03",
  "Lifestyle_04",
  "Lifestyle_05",
  "Lifestyle_06",
  "Lifestyle_07",
  "Lifestyle_08",
  "Lifestyle_09",
  "Lifestyle_10",
]
hero.female.iso.Mask = [
  "Mask_01",
  "Mask_02",
  "Mask_03",
  "Mask_04",
  "Mask_05",
  "Mask_06",
  "Mask_07",
  "Mask_08",
  "Mask_09",
  "Mask_10",
]
hero.female.iso.Shoes = [
  "Shoes_00",
  "Shoes_01",
  "Shoes_02",
  "Shoes_03",
  "Shoes_04",
  "Shoes_05",
  "Shoes_06",
  "Shoes_07",
  "Shoes_08",
  "Shoes_09",
  "Shoes_10",
]
hero.female.iso.Skin = ["Skin_01", "Skin_02", "Skin_03", "Skin_04"]
hero.female.iso.Suit = [
  "Suit_00",
  "Suit_01",
  "Suit_02",
  "Suit_03",
  "Suit_04",
  "Suit_05",
  "Suit_06",
  "Suit_07",
  "Suit_08",
  "Suit_09",
  "Suit_10",
]
hero.female.perspective.Accessoire_01 = new URL(
  "./img/heroes/Hero_Female_Perspective-Accessoire_01.png",
  import.meta.url,
)
hero.female.perspective.Accessoire_02 = new URL(
  "./img/heroes/Hero_Female_Perspective-Accessoire_02.png",
  import.meta.url,
)
hero.female.perspective.Accessoire_03 = new URL(
  "./img/heroes/Hero_Female_Perspective-Accessoire_03.png",
  import.meta.url,
)
hero.female.perspective.Accessoire_04 = new URL(
  "./img/heroes/Hero_Female_Perspective-Accessoire_04.png",
  import.meta.url,
)
hero.female.perspective.Accessoire_05 = new URL(
  "./img/heroes/Hero_Female_Perspective-Accessoire_05.png",
  import.meta.url,
)
hero.female.perspective.Accessoire_06 = new URL(
  "./img/heroes/Hero_Female_Perspective-Accessoire_06.png",
  import.meta.url,
)
hero.female.perspective.Accessoire_07 = new URL(
  "./img/heroes/Hero_Female_Perspective-Accessoire_07.png",
  import.meta.url,
)
hero.female.perspective.Accessoire_08 = new URL(
  "./img/heroes/Hero_Female_Perspective-Accessoire_08.png",
  import.meta.url,
)
hero.female.perspective.Accessoire_09 = new URL(
  "./img/heroes/Hero_Female_Perspective-Accessoire_09.png",
  import.meta.url,
)
hero.female.perspective.Accessoire_10 = new URL(
  "./img/heroes/Hero_Female_Perspective-Accessoire_10.png",
  import.meta.url,
)
hero.female.perspective.Cape_01 = new URL(
  "./img/heroes/Hero_Female_Perspective-Cape_01.png",
  import.meta.url,
)
hero.female.perspective.Cape_02 = new URL(
  "./img/heroes/Hero_Female_Perspective-Cape_02.png",
  import.meta.url,
)
hero.female.perspective.Cape_03 = new URL(
  "./img/heroes/Hero_Female_Perspective-Cape_03.png",
  import.meta.url,
)
hero.female.perspective.Cape_04 = new URL(
  "./img/heroes/Hero_Female_Perspective-Cape_04.png",
  import.meta.url,
)
hero.female.perspective.Cape_05 = new URL(
  "./img/heroes/Hero_Female_Perspective-Cape_05.png",
  import.meta.url,
)
hero.female.perspective.Cape_06 = new URL(
  "./img/heroes/Hero_Female_Perspective-Cape_06.png",
  import.meta.url,
)
hero.female.perspective.Cape_07 = new URL(
  "./img/heroes/Hero_Female_Perspective-Cape_07.png",
  import.meta.url,
)
hero.female.perspective.Cape_08 = new URL(
  "./img/heroes/Hero_Female_Perspective-Cape_08.png",
  import.meta.url,
)
hero.female.perspective.Cape_09 = new URL(
  "./img/heroes/Hero_Female_Perspective-Cape_09.png",
  import.meta.url,
)
hero.female.perspective.Cape_10 = new URL(
  "./img/heroes/Hero_Female_Perspective-Cape_10.png",
  import.meta.url,
)
hero.female.perspective.Hair_01_Black = new URL(
  "./img/heroes/Hero_Female_Perspective-Hair_01_Black.png",
  import.meta.url,
)
hero.female.perspective.Hair_01_Blonde = new URL(
  "./img/heroes/Hero_Female_Perspective-Hair_01_Blonde.png",
  import.meta.url,
)
hero.female.perspective.Hair_01_Blue = new URL(
  "./img/heroes/Hero_Female_Perspective-Hair_01_Blue.png",
  import.meta.url,
)
hero.female.perspective.Hair_01_Brown = new URL(
  "./img/heroes/Hero_Female_Perspective-Hair_01_Brown.png",
  import.meta.url,
)
hero.female.perspective.Hair_01_Red = new URL(
  "./img/heroes/Hero_Female_Perspective-Hair_01_Red.png",
  import.meta.url,
)
hero.female.perspective.Hair_02_Black = new URL(
  "./img/heroes/Hero_Female_Perspective-Hair_02_Black.png",
  import.meta.url,
)
hero.female.perspective.Hair_02_Blonde = new URL(
  "./img/heroes/Hero_Female_Perspective-Hair_02_Blonde.png",
  import.meta.url,
)
hero.female.perspective.Hair_02_Blue = new URL(
  "./img/heroes/Hero_Female_Perspective-Hair_02_Blue.png",
  import.meta.url,
)
hero.female.perspective.Hair_02_Brown = new URL(
  "./img/heroes/Hero_Female_Perspective-Hair_02_Brown.png",
  import.meta.url,
)
hero.female.perspective.Hair_02_Red = new URL(
  "./img/heroes/Hero_Female_Perspective-Hair_02_Red.png",
  import.meta.url,
)
hero.female.perspective.Hair_03_Black = new URL(
  "./img/heroes/Hero_Female_Perspective-Hair_03_Black.png",
  import.meta.url,
)
hero.female.perspective.Hair_03_Blonde = new URL(
  "./img/heroes/Hero_Female_Perspective-Hair_03_Blonde.png",
  import.meta.url,
)
hero.female.perspective.Hair_03_Blue = new URL(
  "./img/heroes/Hero_Female_Perspective-Hair_03_Blue.png",
  import.meta.url,
)
hero.female.perspective.Hair_03_Brown = new URL(
  "./img/heroes/Hero_Female_Perspective-Hair_03_Brown.png",
  import.meta.url,
)
hero.female.perspective.Hair_03_Red = new URL(
  "./img/heroes/Hero_Female_Perspective-Hair_03_Red.png",
  import.meta.url,
)
hero.female.perspective.Hair_04_Black = new URL(
  "./img/heroes/Hero_Female_Perspective-Hair_04_Black.png",
  import.meta.url,
)
hero.female.perspective.Hair_04_Blonde = new URL(
  "./img/heroes/Hero_Female_Perspective-Hair_04_Blonde.png",
  import.meta.url,
)
hero.female.perspective.Hair_04_Blue = new URL(
  "./img/heroes/Hero_Female_Perspective-Hair_04_Blue.png",
  import.meta.url,
)
hero.female.perspective.Hair_04_Brown = new URL(
  "./img/heroes/Hero_Female_Perspective-Hair_04_Brown.png",
  import.meta.url,
)
hero.female.perspective.Hair_04_Red = new URL(
  "./img/heroes/Hero_Female_Perspective-Hair_04_Red.png",
  import.meta.url,
)
hero.female.perspective.Hair_05_Black = new URL(
  "./img/heroes/Hero_Female_Perspective-Hair_05_Black.png",
  import.meta.url,
)
hero.female.perspective.Hair_05_Blonde = new URL(
  "./img/heroes/Hero_Female_Perspective-Hair_05_Blonde.png",
  import.meta.url,
)
hero.female.perspective.Hair_05_Blue = new URL(
  "./img/heroes/Hero_Female_Perspective-Hair_05_Blue.png",
  import.meta.url,
)
hero.female.perspective.Hair_05_Brown = new URL(
  "./img/heroes/Hero_Female_Perspective-Hair_05_Brown.png",
  import.meta.url,
)
hero.female.perspective.Hair_05_Red = new URL(
  "./img/heroes/Hero_Female_Perspective-Hair_05_Red.png",
  import.meta.url,
)
hero.female.perspective.Lifestyle_01 = new URL(
  "./img/heroes/Hero_Female_Perspective-Lifestyle_01.png",
  import.meta.url,
)
hero.female.perspective.Lifestyle_02 = new URL(
  "./img/heroes/Hero_Female_Perspective-Lifestyle_02.png",
  import.meta.url,
)
hero.female.perspective.Lifestyle_03 = new URL(
  "./img/heroes/Hero_Female_Perspective-Lifestyle_03.png",
  import.meta.url,
)
hero.female.perspective.Lifestyle_04 = new URL(
  "./img/heroes/Hero_Female_Perspective-Lifestyle_04.png",
  import.meta.url,
)
hero.female.perspective.Lifestyle_05 = new URL(
  "./img/heroes/Hero_Female_Perspective-Lifestyle_05.png",
  import.meta.url,
)
hero.female.perspective.Lifestyle_06 = new URL(
  "./img/heroes/Hero_Female_Perspective-Lifestyle_06.png",
  import.meta.url,
)
hero.female.perspective.Lifestyle_07 = new URL(
  "./img/heroes/Hero_Female_Perspective-Lifestyle_07.png",
  import.meta.url,
)
hero.female.perspective.Lifestyle_08 = new URL(
  "./img/heroes/Hero_Female_Perspective-Lifestyle_08.png",
  import.meta.url,
)
hero.female.perspective.Lifestyle_09 = new URL(
  "./img/heroes/Hero_Female_Perspective-Lifestyle_09.png",
  import.meta.url,
)
hero.female.perspective.Lifestyle_10 = new URL(
  "./img/heroes/Hero_Female_Perspective-Lifestyle_10.png",
  import.meta.url,
)
hero.female.perspective.Mask_01 = new URL(
  "./img/heroes/Hero_Female_Perspective-Mask_01.png",
  import.meta.url,
)
hero.female.perspective.Mask_02 = new URL(
  "./img/heroes/Hero_Female_Perspective-Mask_02.png",
  import.meta.url,
)
hero.female.perspective.Mask_03 = new URL(
  "./img/heroes/Hero_Female_Perspective-Mask_03.png",
  import.meta.url,
)
hero.female.perspective.Mask_04 = new URL(
  "./img/heroes/Hero_Female_Perspective-Mask_04.png",
  import.meta.url,
)
hero.female.perspective.Mask_05 = new URL(
  "./img/heroes/Hero_Female_Perspective-Mask_05.png",
  import.meta.url,
)
hero.female.perspective.Mask_06 = new URL(
  "./img/heroes/Hero_Female_Perspective-Mask_06.png",
  import.meta.url,
)
hero.female.perspective.Mask_07 = new URL(
  "./img/heroes/Hero_Female_Perspective-Mask_07.png",
  import.meta.url,
)
hero.female.perspective.Mask_08 = new URL(
  "./img/heroes/Hero_Female_Perspective-Mask_08.png",
  import.meta.url,
)
hero.female.perspective.Mask_09 = new URL(
  "./img/heroes/Hero_Female_Perspective-Mask_09.png",
  import.meta.url,
)
hero.female.perspective.Mask_10 = new URL(
  "./img/heroes/Hero_Female_Perspective-Mask_10.png",
  import.meta.url,
)
hero.female.perspective.Shoes_00 = new URL(
  "./img/heroes/Hero_Female_Perspective-Shoes_00.png",
  import.meta.url,
)
hero.female.perspective.Shoes_01 = new URL(
  "./img/heroes/Hero_Female_Perspective-Shoes_01.png",
  import.meta.url,
)
hero.female.perspective.Shoes_02 = new URL(
  "./img/heroes/Hero_Female_Perspective-Shoes_02.png",
  import.meta.url,
)
hero.female.perspective.Shoes_03 = new URL(
  "./img/heroes/Hero_Female_Perspective-Shoes_03.png",
  import.meta.url,
)
hero.female.perspective.Shoes_04 = new URL(
  "./img/heroes/Hero_Female_Perspective-Shoes_04.png",
  import.meta.url,
)
hero.female.perspective.Shoes_05 = new URL(
  "./img/heroes/Hero_Female_Perspective-Shoes_05.png",
  import.meta.url,
)
hero.female.perspective.Shoes_06 = new URL(
  "./img/heroes/Hero_Female_Perspective-Shoes_06.png",
  import.meta.url,
)
hero.female.perspective.Shoes_07 = new URL(
  "./img/heroes/Hero_Female_Perspective-Shoes_07.png",
  import.meta.url,
)
hero.female.perspective.Shoes_08 = new URL(
  "./img/heroes/Hero_Female_Perspective-Shoes_08.png",
  import.meta.url,
)
hero.female.perspective.Shoes_09 = new URL(
  "./img/heroes/Hero_Female_Perspective-Shoes_09.png",
  import.meta.url,
)
hero.female.perspective.Shoes_10 = new URL(
  "./img/heroes/Hero_Female_Perspective-Shoes_10.png",
  import.meta.url,
)
hero.female.perspective.Skin_01 = new URL(
  "./img/heroes/Hero_Female_Perspective-Skin_01.png",
  import.meta.url,
)
hero.female.perspective.Skin_02 = new URL(
  "./img/heroes/Hero_Female_Perspective-Skin_02.png",
  import.meta.url,
)
hero.female.perspective.Skin_03 = new URL(
  "./img/heroes/Hero_Female_Perspective-Skin_03.png",
  import.meta.url,
)
hero.female.perspective.Skin_04 = new URL(
  "./img/heroes/Hero_Female_Perspective-Skin_04.png",
  import.meta.url,
)
hero.female.perspective.Suit_00 = new URL(
  "./img/heroes/Hero_Female_Perspective-Suit_00.png",
  import.meta.url,
)
hero.female.perspective.Suit_01 = new URL(
  "./img/heroes/Hero_Female_Perspective-Suit_01.png",
  import.meta.url,
)
hero.female.perspective.Suit_02 = new URL(
  "./img/heroes/Hero_Female_Perspective-Suit_02.png",
  import.meta.url,
)
hero.female.perspective.Suit_03 = new URL(
  "./img/heroes/Hero_Female_Perspective-Suit_03.png",
  import.meta.url,
)
hero.female.perspective.Suit_04 = new URL(
  "./img/heroes/Hero_Female_Perspective-Suit_04.png",
  import.meta.url,
)
hero.female.perspective.Suit_05 = new URL(
  "./img/heroes/Hero_Female_Perspective-Suit_05.png",
  import.meta.url,
)
hero.female.perspective.Suit_06 = new URL(
  "./img/heroes/Hero_Female_Perspective-Suit_06.png",
  import.meta.url,
)
hero.female.perspective.Suit_07 = new URL(
  "./img/heroes/Hero_Female_Perspective-Suit_07.png",
  import.meta.url,
)
hero.female.perspective.Suit_08 = new URL(
  "./img/heroes/Hero_Female_Perspective-Suit_08.png",
  import.meta.url,
)
hero.female.perspective.Suit_09 = new URL(
  "./img/heroes/Hero_Female_Perspective-Suit_09.png",
  import.meta.url,
)
hero.female.perspective.Suit_10 = new URL(
  "./img/heroes/Hero_Female_Perspective-Suit_10.png",
  import.meta.url,
)
hero.female.perspective.Accessoire = [
  "Accessoire_01",
  "Accessoire_02",
  "Accessoire_03",
  "Accessoire_04",
  "Accessoire_05",
  "Accessoire_06",
  "Accessoire_07",
  "Accessoire_08",
  "Accessoire_09",
  "Accessoire_10",
]
hero.female.perspective.Cape = [
  "Cape_01",
  "Cape_02",
  "Cape_03",
  "Cape_04",
  "Cape_05",
  "Cape_06",
  "Cape_07",
  "Cape_08",
  "Cape_09",
  "Cape_10",
]
hero.female.perspective.Hair = [
  "Hair_01_Black",
  "Hair_01_Blonde",
  "Hair_01_Blue",
  "Hair_01_Brown",
  "Hair_01_Red",
  "Hair_02_Black",
  "Hair_02_Blonde",
  "Hair_02_Blue",
  "Hair_02_Brown",
  "Hair_02_Red",
  "Hair_03_Black",
  "Hair_03_Blonde",
  "Hair_03_Blue",
  "Hair_03_Brown",
  "Hair_03_Red",
  "Hair_04_Black",
  "Hair_04_Blonde",
  "Hair_04_Blue",
  "Hair_04_Brown",
  "Hair_04_Red",
  "Hair_05_Black",
  "Hair_05_Blonde",
  "Hair_05_Blue",
  "Hair_05_Brown",
  "Hair_05_Red",
]
hero.female.perspective.Lifestyle = [
  "Lifestyle_01",
  "Lifestyle_02",
  "Lifestyle_03",
  "Lifestyle_04",
  "Lifestyle_05",
  "Lifestyle_06",
  "Lifestyle_07",
  "Lifestyle_08",
  "Lifestyle_09",
  "Lifestyle_10",
]
hero.female.perspective.Mask = [
  "Mask_01",
  "Mask_02",
  "Mask_03",
  "Mask_04",
  "Mask_05",
  "Mask_06",
  "Mask_07",
  "Mask_08",
  "Mask_09",
  "Mask_10",
]
hero.female.perspective.Shoes = [
  "Shoes_00",
  "Shoes_01",
  "Shoes_02",
  "Shoes_03",
  "Shoes_04",
  "Shoes_05",
  "Shoes_06",
  "Shoes_07",
  "Shoes_08",
  "Shoes_09",
  "Shoes_10",
]
hero.female.perspective.Skin = ["Skin_01", "Skin_02", "Skin_03", "Skin_04"]
hero.female.perspective.Suit = [
  "Suit_00",
  "Suit_01",
  "Suit_02",
  "Suit_03",
  "Suit_04",
  "Suit_05",
  "Suit_06",
  "Suit_07",
  "Suit_08",
  "Suit_09",
  "Suit_10",
]
hero.male.iso.Accessoire_01 = new URL(
  "./img/heroes/Hero_Male_Iso-Accessoire_01.png",
  import.meta.url,
)
hero.male.iso.Accessoire_02 = new URL(
  "./img/heroes/Hero_Male_Iso-Accessoire_02.png",
  import.meta.url,
)
hero.male.iso.Accessoire_03 = new URL(
  "./img/heroes/Hero_Male_Iso-Accessoire_03.png",
  import.meta.url,
)
hero.male.iso.Accessoire_04 = new URL(
  "./img/heroes/Hero_Male_Iso-Accessoire_04.png",
  import.meta.url,
)
hero.male.iso.Accessoire_05 = new URL(
  "./img/heroes/Hero_Male_Iso-Accessoire_05.png",
  import.meta.url,
)
hero.male.iso.Accessoire_06 = new URL(
  "./img/heroes/Hero_Male_Iso-Accessoire_06.png",
  import.meta.url,
)
hero.male.iso.Accessoire_07 = new URL(
  "./img/heroes/Hero_Male_Iso-Accessoire_07.png",
  import.meta.url,
)
hero.male.iso.Accessoire_08 = new URL(
  "./img/heroes/Hero_Male_Iso-Accessoire_08.png",
  import.meta.url,
)
hero.male.iso.Accessoire_09 = new URL(
  "./img/heroes/Hero_Male_Iso-Accessoire_09.png",
  import.meta.url,
)
hero.male.iso.Accessoire_10 = new URL(
  "./img/heroes/Hero_Male_Iso-Accessoire_10.png",
  import.meta.url,
)
hero.male.iso.Cape_01 = new URL(
  "./img/heroes/Hero_Male_Iso-Cape_01.png",
  import.meta.url,
)
hero.male.iso.Cape_02 = new URL(
  "./img/heroes/Hero_Male_Iso-Cape_02.png",
  import.meta.url,
)
hero.male.iso.Cape_03 = new URL(
  "./img/heroes/Hero_Male_Iso-Cape_03.png",
  import.meta.url,
)
hero.male.iso.Cape_04 = new URL(
  "./img/heroes/Hero_Male_Iso-Cape_04.png",
  import.meta.url,
)
hero.male.iso.Cape_05 = new URL(
  "./img/heroes/Hero_Male_Iso-Cape_05.png",
  import.meta.url,
)
hero.male.iso.Cape_06 = new URL(
  "./img/heroes/Hero_Male_Iso-Cape_06.png",
  import.meta.url,
)
hero.male.iso.Cape_07 = new URL(
  "./img/heroes/Hero_Male_Iso-Cape_07.png",
  import.meta.url,
)
hero.male.iso.Cape_08 = new URL(
  "./img/heroes/Hero_Male_Iso-Cape_08.png",
  import.meta.url,
)
hero.male.iso.Cape_09 = new URL(
  "./img/heroes/Hero_Male_Iso-Cape_09.png",
  import.meta.url,
)
hero.male.iso.Cape_10 = new URL(
  "./img/heroes/Hero_Male_Iso-Cape_10.png",
  import.meta.url,
)
hero.male.iso.Hair_01_Black = new URL(
  "./img/heroes/Hero_Male_Iso-Hair_01_Black.png",
  import.meta.url,
)
hero.male.iso.Hair_01_Blonde = new URL(
  "./img/heroes/Hero_Male_Iso-Hair_01_Blonde.png",
  import.meta.url,
)
hero.male.iso.Hair_01_Blue = new URL(
  "./img/heroes/Hero_Male_Iso-Hair_01_Blue.png",
  import.meta.url,
)
hero.male.iso.Hair_01_Brown = new URL(
  "./img/heroes/Hero_Male_Iso-Hair_01_Brown.png",
  import.meta.url,
)
hero.male.iso.Hair_01_Red = new URL(
  "./img/heroes/Hero_Male_Iso-Hair_01_Red.png",
  import.meta.url,
)
hero.male.iso.Hair_02_Black = new URL(
  "./img/heroes/Hero_Male_Iso-Hair_02_Black.png",
  import.meta.url,
)
hero.male.iso.Hair_02_Blonde = new URL(
  "./img/heroes/Hero_Male_Iso-Hair_02_Blonde.png",
  import.meta.url,
)
hero.male.iso.Hair_02_Blue = new URL(
  "./img/heroes/Hero_Male_Iso-Hair_02_Blue.png",
  import.meta.url,
)
hero.male.iso.Hair_02_Brown = new URL(
  "./img/heroes/Hero_Male_Iso-Hair_02_Brown.png",
  import.meta.url,
)
hero.male.iso.Hair_02_Red = new URL(
  "./img/heroes/Hero_Male_Iso-Hair_02_Red.png",
  import.meta.url,
)
hero.male.iso.Hair_03_Black = new URL(
  "./img/heroes/Hero_Male_Iso-Hair_03_Black.png",
  import.meta.url,
)
hero.male.iso.Hair_03_Blonde = new URL(
  "./img/heroes/Hero_Male_Iso-Hair_03_Blonde.png",
  import.meta.url,
)
hero.male.iso.Hair_03_Blue = new URL(
  "./img/heroes/Hero_Male_Iso-Hair_03_Blue.png",
  import.meta.url,
)
hero.male.iso.Hair_03_Brown = new URL(
  "./img/heroes/Hero_Male_Iso-Hair_03_Brown.png",
  import.meta.url,
)
hero.male.iso.Hair_03_Red = new URL(
  "./img/heroes/Hero_Male_Iso-Hair_03_Red.png",
  import.meta.url,
)
hero.male.iso.Hair_04_Black = new URL(
  "./img/heroes/Hero_Male_Iso-Hair_04_Black.png",
  import.meta.url,
)
hero.male.iso.Hair_04_Blonde = new URL(
  "./img/heroes/Hero_Male_Iso-Hair_04_Blonde.png",
  import.meta.url,
)
hero.male.iso.Hair_04_Blue = new URL(
  "./img/heroes/Hero_Male_Iso-Hair_04_Blue.png",
  import.meta.url,
)
hero.male.iso.Hair_04_Brown = new URL(
  "./img/heroes/Hero_Male_Iso-Hair_04_Brown.png",
  import.meta.url,
)
hero.male.iso.Hair_04_Red = new URL(
  "./img/heroes/Hero_Male_Iso-Hair_04_Red.png",
  import.meta.url,
)
hero.male.iso.Hair_05_Black = new URL(
  "./img/heroes/Hero_Male_Iso-Hair_05_Black.png",
  import.meta.url,
)
hero.male.iso.Hair_05_Blonde = new URL(
  "./img/heroes/Hero_Male_Iso-Hair_05_Blonde.png",
  import.meta.url,
)
hero.male.iso.Hair_05_Blue = new URL(
  "./img/heroes/Hero_Male_Iso-Hair_05_Blue.png",
  import.meta.url,
)
hero.male.iso.Hair_05_Brown = new URL(
  "./img/heroes/Hero_Male_Iso-Hair_05_Brown.png",
  import.meta.url,
)
hero.male.iso.Hair_05_Red = new URL(
  "./img/heroes/Hero_Male_Iso-Hair_05_Red.png",
  import.meta.url,
)
hero.male.iso.Lifestyle_01 = new URL(
  "./img/heroes/Hero_Male_Iso-Lifestyle_01.png",
  import.meta.url,
)
hero.male.iso.Lifestyle_02 = new URL(
  "./img/heroes/Hero_Male_Iso-Lifestyle_02.png",
  import.meta.url,
)
hero.male.iso.Lifestyle_03 = new URL(
  "./img/heroes/Hero_Male_Iso-Lifestyle_03.png",
  import.meta.url,
)
hero.male.iso.Lifestyle_04 = new URL(
  "./img/heroes/Hero_Male_Iso-Lifestyle_04.png",
  import.meta.url,
)
hero.male.iso.Lifestyle_05 = new URL(
  "./img/heroes/Hero_Male_Iso-Lifestyle_05.png",
  import.meta.url,
)
hero.male.iso.Lifestyle_06 = new URL(
  "./img/heroes/Hero_Male_Iso-Lifestyle_06.png",
  import.meta.url,
)
hero.male.iso.Lifestyle_07 = new URL(
  "./img/heroes/Hero_Male_Iso-Lifestyle_07.png",
  import.meta.url,
)
hero.male.iso.Lifestyle_08 = new URL(
  "./img/heroes/Hero_Male_Iso-Lifestyle_08.png",
  import.meta.url,
)
hero.male.iso.Lifestyle_09 = new URL(
  "./img/heroes/Hero_Male_Iso-Lifestyle_09.png",
  import.meta.url,
)
hero.male.iso.Lifestyle_10 = new URL(
  "./img/heroes/Hero_Male_Iso-Lifestyle_10.png",
  import.meta.url,
)
hero.male.iso.Mask_01 = new URL(
  "./img/heroes/Hero_Male_Iso-Mask_01.png",
  import.meta.url,
)
hero.male.iso.Mask_02 = new URL(
  "./img/heroes/Hero_Male_Iso-Mask_02.png",
  import.meta.url,
)
hero.male.iso.Mask_03 = new URL(
  "./img/heroes/Hero_Male_Iso-Mask_03.png",
  import.meta.url,
)
hero.male.iso.Mask_04 = new URL(
  "./img/heroes/Hero_Male_Iso-Mask_04.png",
  import.meta.url,
)
hero.male.iso.Mask_05 = new URL(
  "./img/heroes/Hero_Male_Iso-Mask_05.png",
  import.meta.url,
)
hero.male.iso.Mask_06 = new URL(
  "./img/heroes/Hero_Male_Iso-Mask_06.png",
  import.meta.url,
)
hero.male.iso.Mask_07 = new URL(
  "./img/heroes/Hero_Male_Iso-Mask_07.png",
  import.meta.url,
)
hero.male.iso.Mask_08 = new URL(
  "./img/heroes/Hero_Male_Iso-Mask_08.png",
  import.meta.url,
)
hero.male.iso.Mask_09 = new URL(
  "./img/heroes/Hero_Male_Iso-Mask_09.png",
  import.meta.url,
)
hero.male.iso.Mask_10 = new URL(
  "./img/heroes/Hero_Male_Iso-Mask_10.png",
  import.meta.url,
)
hero.male.iso.Shoes_00 = new URL(
  "./img/heroes/Hero_Male_Iso-Shoes_00.png",
  import.meta.url,
)
hero.male.iso.Shoes_01 = new URL(
  "./img/heroes/Hero_Male_Iso-Shoes_01.png",
  import.meta.url,
)
hero.male.iso.Shoes_02 = new URL(
  "./img/heroes/Hero_Male_Iso-Shoes_02.png",
  import.meta.url,
)
hero.male.iso.Shoes_03 = new URL(
  "./img/heroes/Hero_Male_Iso-Shoes_03.png",
  import.meta.url,
)
hero.male.iso.Shoes_04 = new URL(
  "./img/heroes/Hero_Male_Iso-Shoes_04.png",
  import.meta.url,
)
hero.male.iso.Shoes_05 = new URL(
  "./img/heroes/Hero_Male_Iso-Shoes_05.png",
  import.meta.url,
)
hero.male.iso.Shoes_06 = new URL(
  "./img/heroes/Hero_Male_Iso-Shoes_06.png",
  import.meta.url,
)
hero.male.iso.Shoes_07 = new URL(
  "./img/heroes/Hero_Male_Iso-Shoes_07.png",
  import.meta.url,
)
hero.male.iso.Shoes_08 = new URL(
  "./img/heroes/Hero_Male_Iso-Shoes_08.png",
  import.meta.url,
)
hero.male.iso.Shoes_09 = new URL(
  "./img/heroes/Hero_Male_Iso-Shoes_09.png",
  import.meta.url,
)
hero.male.iso.Shoes_10 = new URL(
  "./img/heroes/Hero_Male_Iso-Shoes_10.png",
  import.meta.url,
)
hero.male.iso.Skin_01 = new URL(
  "./img/heroes/Hero_Male_Iso-Skin_01.png",
  import.meta.url,
)
hero.male.iso.Skin_02 = new URL(
  "./img/heroes/Hero_Male_Iso-Skin_02.png",
  import.meta.url,
)
hero.male.iso.Skin_03 = new URL(
  "./img/heroes/Hero_Male_Iso-Skin_03.png",
  import.meta.url,
)
hero.male.iso.Skin_04 = new URL(
  "./img/heroes/Hero_Male_Iso-Skin_04.png",
  import.meta.url,
)
hero.male.iso.Suit_00 = new URL(
  "./img/heroes/Hero_Male_Iso-Suit_00.png",
  import.meta.url,
)
hero.male.iso.Suit_01 = new URL(
  "./img/heroes/Hero_Male_Iso-Suit_01.png",
  import.meta.url,
)
hero.male.iso.Suit_02 = new URL(
  "./img/heroes/Hero_Male_Iso-Suit_02.png",
  import.meta.url,
)
hero.male.iso.Suit_03 = new URL(
  "./img/heroes/Hero_Male_Iso-Suit_03.png",
  import.meta.url,
)
hero.male.iso.Suit_04 = new URL(
  "./img/heroes/Hero_Male_Iso-Suit_04.png",
  import.meta.url,
)
hero.male.iso.Suit_05 = new URL(
  "./img/heroes/Hero_Male_Iso-Suit_05.png",
  import.meta.url,
)
hero.male.iso.Suit_06 = new URL(
  "./img/heroes/Hero_Male_Iso-Suit_06.png",
  import.meta.url,
)
hero.male.iso.Suit_07 = new URL(
  "./img/heroes/Hero_Male_Iso-Suit_07.png",
  import.meta.url,
)
hero.male.iso.Suit_08 = new URL(
  "./img/heroes/Hero_Male_Iso-Suit_08.png",
  import.meta.url,
)
hero.male.iso.Suit_09 = new URL(
  "./img/heroes/Hero_Male_Iso-Suit_09.png",
  import.meta.url,
)
hero.male.iso.Suit_10 = new URL(
  "./img/heroes/Hero_Male_Iso-Suit_10.png",
  import.meta.url,
)
hero.male.iso.Accessoire = [
  "Accessoire_01",
  "Accessoire_02",
  "Accessoire_03",
  "Accessoire_04",
  "Accessoire_05",
  "Accessoire_06",
  "Accessoire_07",
  "Accessoire_08",
  "Accessoire_09",
  "Accessoire_10",
]
hero.male.iso.Cape = [
  "Cape_01",
  "Cape_02",
  "Cape_03",
  "Cape_04",
  "Cape_05",
  "Cape_06",
  "Cape_07",
  "Cape_08",
  "Cape_09",
  "Cape_10",
]
hero.male.iso.Hair = [
  "Hair_01_Black",
  "Hair_01_Blonde",
  "Hair_01_Blue",
  "Hair_01_Brown",
  "Hair_01_Red",
  "Hair_02_Black",
  "Hair_02_Blonde",
  "Hair_02_Blue",
  "Hair_02_Brown",
  "Hair_02_Red",
  "Hair_03_Black",
  "Hair_03_Blonde",
  "Hair_03_Blue",
  "Hair_03_Brown",
  "Hair_03_Red",
  "Hair_04_Black",
  "Hair_04_Blonde",
  "Hair_04_Blue",
  "Hair_04_Brown",
  "Hair_04_Red",
  "Hair_05_Black",
  "Hair_05_Blonde",
  "Hair_05_Blue",
  "Hair_05_Brown",
  "Hair_05_Red",
]
hero.male.iso.Lifestyle = [
  "Lifestyle_01",
  "Lifestyle_02",
  "Lifestyle_03",
  "Lifestyle_04",
  "Lifestyle_05",
  "Lifestyle_06",
  "Lifestyle_07",
  "Lifestyle_08",
  "Lifestyle_09",
  "Lifestyle_10",
]
hero.male.iso.Mask = [
  "Mask_01",
  "Mask_02",
  "Mask_03",
  "Mask_04",
  "Mask_05",
  "Mask_06",
  "Mask_07",
  "Mask_08",
  "Mask_09",
  "Mask_10",
]
hero.male.iso.Shoes = [
  "Shoes_00",
  "Shoes_01",
  "Shoes_02",
  "Shoes_03",
  "Shoes_04",
  "Shoes_05",
  "Shoes_06",
  "Shoes_07",
  "Shoes_08",
  "Shoes_09",
  "Shoes_10",
]
hero.male.iso.Skin = ["Skin_01", "Skin_02", "Skin_03", "Skin_04"]
hero.male.iso.Suit = [
  "Suit_00",
  "Suit_01",
  "Suit_02",
  "Suit_03",
  "Suit_04",
  "Suit_05",
  "Suit_06",
  "Suit_07",
  "Suit_08",
  "Suit_09",
  "Suit_10",
]
hero.male.perspective.Accessoire_01 = new URL(
  "./img/heroes/Hero_Male_Perspective-Accessoire_01.png",
  import.meta.url,
)
hero.male.perspective.Accessoire_02 = new URL(
  "./img/heroes/Hero_Male_Perspective-Accessoire_02.png",
  import.meta.url,
)
hero.male.perspective.Accessoire_03 = new URL(
  "./img/heroes/Hero_Male_Perspective-Accessoire_03.png",
  import.meta.url,
)
hero.male.perspective.Accessoire_04 = new URL(
  "./img/heroes/Hero_Male_Perspective-Accessoire_04.png",
  import.meta.url,
)
hero.male.perspective.Accessoire_05 = new URL(
  "./img/heroes/Hero_Male_Perspective-Accessoire_05.png",
  import.meta.url,
)
hero.male.perspective.Accessoire_06 = new URL(
  "./img/heroes/Hero_Male_Perspective-Accessoire_06.png",
  import.meta.url,
)
hero.male.perspective.Accessoire_07 = new URL(
  "./img/heroes/Hero_Male_Perspective-Accessoire_07.png",
  import.meta.url,
)
hero.male.perspective.Accessoire_08 = new URL(
  "./img/heroes/Hero_Male_Perspective-Accessoire_08.png",
  import.meta.url,
)
hero.male.perspective.Accessoire_09 = new URL(
  "./img/heroes/Hero_Male_Perspective-Accessoire_09.png",
  import.meta.url,
)
hero.male.perspective.Accessoire_10 = new URL(
  "./img/heroes/Hero_Male_Perspective-Accessoire_10.png",
  import.meta.url,
)
hero.male.perspective.Cape_01 = new URL(
  "./img/heroes/Hero_Male_Perspective-Cape_01.png",
  import.meta.url,
)
hero.male.perspective.Cape_02 = new URL(
  "./img/heroes/Hero_Male_Perspective-Cape_02.png",
  import.meta.url,
)
hero.male.perspective.Cape_03 = new URL(
  "./img/heroes/Hero_Male_Perspective-Cape_03.png",
  import.meta.url,
)
hero.male.perspective.Cape_04 = new URL(
  "./img/heroes/Hero_Male_Perspective-Cape_04.png",
  import.meta.url,
)
hero.male.perspective.Cape_05 = new URL(
  "./img/heroes/Hero_Male_Perspective-Cape_05.png",
  import.meta.url,
)
hero.male.perspective.Cape_06 = new URL(
  "./img/heroes/Hero_Male_Perspective-Cape_06.png",
  import.meta.url,
)
hero.male.perspective.Cape_07 = new URL(
  "./img/heroes/Hero_Male_Perspective-Cape_07.png",
  import.meta.url,
)
hero.male.perspective.Cape_08 = new URL(
  "./img/heroes/Hero_Male_Perspective-Cape_08.png",
  import.meta.url,
)
hero.male.perspective.Cape_09 = new URL(
  "./img/heroes/Hero_Male_Perspective-Cape_09.png",
  import.meta.url,
)
hero.male.perspective.Cape_10 = new URL(
  "./img/heroes/Hero_Male_Perspective-Cape_10.png",
  import.meta.url,
)
hero.male.perspective.Hair_01_Black = new URL(
  "./img/heroes/Hero_Male_Perspective-Hair_01_Black.png",
  import.meta.url,
)
hero.male.perspective.Hair_01_Blonde = new URL(
  "./img/heroes/Hero_Male_Perspective-Hair_01_Blonde.png",
  import.meta.url,
)
hero.male.perspective.Hair_01_Blue = new URL(
  "./img/heroes/Hero_Male_Perspective-Hair_01_Blue.png",
  import.meta.url,
)
hero.male.perspective.Hair_01_Brown = new URL(
  "./img/heroes/Hero_Male_Perspective-Hair_01_Brown.png",
  import.meta.url,
)
hero.male.perspective.Hair_01_Red = new URL(
  "./img/heroes/Hero_Male_Perspective-Hair_01_Red.png",
  import.meta.url,
)
hero.male.perspective.Hair_02_Black = new URL(
  "./img/heroes/Hero_Male_Perspective-Hair_02_Black.png",
  import.meta.url,
)
hero.male.perspective.Hair_02_Blonde = new URL(
  "./img/heroes/Hero_Male_Perspective-Hair_02_Blonde.png",
  import.meta.url,
)
hero.male.perspective.Hair_02_Blue = new URL(
  "./img/heroes/Hero_Male_Perspective-Hair_02_Blue.png",
  import.meta.url,
)
hero.male.perspective.Hair_02_Brown = new URL(
  "./img/heroes/Hero_Male_Perspective-Hair_02_Brown.png",
  import.meta.url,
)
hero.male.perspective.Hair_02_Red = new URL(
  "./img/heroes/Hero_Male_Perspective-Hair_02_Red.png",
  import.meta.url,
)
hero.male.perspective.Hair_03_Black = new URL(
  "./img/heroes/Hero_Male_Perspective-Hair_03_Black.png",
  import.meta.url,
)
hero.male.perspective.Hair_03_Blonde = new URL(
  "./img/heroes/Hero_Male_Perspective-Hair_03_Blonde.png",
  import.meta.url,
)
hero.male.perspective.Hair_03_Blue = new URL(
  "./img/heroes/Hero_Male_Perspective-Hair_03_Blue.png",
  import.meta.url,
)
hero.male.perspective.Hair_03_Brown = new URL(
  "./img/heroes/Hero_Male_Perspective-Hair_03_Brown.png",
  import.meta.url,
)
hero.male.perspective.Hair_03_Red = new URL(
  "./img/heroes/Hero_Male_Perspective-Hair_03_Red.png",
  import.meta.url,
)
hero.male.perspective.Hair_04_Black = new URL(
  "./img/heroes/Hero_Male_Perspective-Hair_04_Black.png",
  import.meta.url,
)
hero.male.perspective.Hair_04_Blonde = new URL(
  "./img/heroes/Hero_Male_Perspective-Hair_04_Blonde.png",
  import.meta.url,
)
hero.male.perspective.Hair_04_Blue = new URL(
  "./img/heroes/Hero_Male_Perspective-Hair_04_Blue.png",
  import.meta.url,
)
hero.male.perspective.Hair_04_Brown = new URL(
  "./img/heroes/Hero_Male_Perspective-Hair_04_Brown.png",
  import.meta.url,
)
hero.male.perspective.Hair_04_Red = new URL(
  "./img/heroes/Hero_Male_Perspective-Hair_04_Red.png",
  import.meta.url,
)
hero.male.perspective.Hair_05_Black = new URL(
  "./img/heroes/Hero_Male_Perspective-Hair_05_Black.png",
  import.meta.url,
)
hero.male.perspective.Hair_05_Blonde = new URL(
  "./img/heroes/Hero_Male_Perspective-Hair_05_Blonde.png",
  import.meta.url,
)
hero.male.perspective.Hair_05_Blue = new URL(
  "./img/heroes/Hero_Male_Perspective-Hair_05_Blue.png",
  import.meta.url,
)
hero.male.perspective.Hair_05_Brown = new URL(
  "./img/heroes/Hero_Male_Perspective-Hair_05_Brown.png",
  import.meta.url,
)
hero.male.perspective.Hair_05_Red = new URL(
  "./img/heroes/Hero_Male_Perspective-Hair_05_Red.png",
  import.meta.url,
)
hero.male.perspective.Lifestyle_01 = new URL(
  "./img/heroes/Hero_Male_Perspective-Lifestyle_01.png",
  import.meta.url,
)
hero.male.perspective.Lifestyle_02 = new URL(
  "./img/heroes/Hero_Male_Perspective-Lifestyle_02.png",
  import.meta.url,
)
hero.male.perspective.Lifestyle_03 = new URL(
  "./img/heroes/Hero_Male_Perspective-Lifestyle_03.png",
  import.meta.url,
)
hero.male.perspective.Lifestyle_04 = new URL(
  "./img/heroes/Hero_Male_Perspective-Lifestyle_04.png",
  import.meta.url,
)
hero.male.perspective.Lifestyle_05 = new URL(
  "./img/heroes/Hero_Male_Perspective-Lifestyle_05.png",
  import.meta.url,
)
hero.male.perspective.Lifestyle_06 = new URL(
  "./img/heroes/Hero_Male_Perspective-Lifestyle_06.png",
  import.meta.url,
)
hero.male.perspective.Lifestyle_07 = new URL(
  "./img/heroes/Hero_Male_Perspective-Lifestyle_07.png",
  import.meta.url,
)
hero.male.perspective.Lifestyle_08 = new URL(
  "./img/heroes/Hero_Male_Perspective-Lifestyle_08.png",
  import.meta.url,
)
hero.male.perspective.Lifestyle_09 = new URL(
  "./img/heroes/Hero_Male_Perspective-Lifestyle_09.png",
  import.meta.url,
)
hero.male.perspective.Lifestyle_10 = new URL(
  "./img/heroes/Hero_Male_Perspective-Lifestyle_10.png",
  import.meta.url,
)
hero.male.perspective.Mask_01 = new URL(
  "./img/heroes/Hero_Male_Perspective-Mask_01.png",
  import.meta.url,
)
hero.male.perspective.Mask_02 = new URL(
  "./img/heroes/Hero_Male_Perspective-Mask_02.png",
  import.meta.url,
)
hero.male.perspective.Mask_03 = new URL(
  "./img/heroes/Hero_Male_Perspective-Mask_03.png",
  import.meta.url,
)
hero.male.perspective.Mask_04 = new URL(
  "./img/heroes/Hero_Male_Perspective-Mask_04.png",
  import.meta.url,
)
hero.male.perspective.Mask_05 = new URL(
  "./img/heroes/Hero_Male_Perspective-Mask_05.png",
  import.meta.url,
)
hero.male.perspective.Mask_06 = new URL(
  "./img/heroes/Hero_Male_Perspective-Mask_06.png",
  import.meta.url,
)
hero.male.perspective.Mask_07 = new URL(
  "./img/heroes/Hero_Male_Perspective-Mask_07.png",
  import.meta.url,
)
hero.male.perspective.Mask_08 = new URL(
  "./img/heroes/Hero_Male_Perspective-Mask_08.png",
  import.meta.url,
)
hero.male.perspective.Mask_09 = new URL(
  "./img/heroes/Hero_Male_Perspective-Mask_09.png",
  import.meta.url,
)
hero.male.perspective.Mask_10 = new URL(
  "./img/heroes/Hero_Male_Perspective-Mask_10.png",
  import.meta.url,
)
hero.male.perspective.Shoes_00 = new URL(
  "./img/heroes/Hero_Male_Perspective-Shoes_00.png",
  import.meta.url,
)
hero.male.perspective.Shoes_01 = new URL(
  "./img/heroes/Hero_Male_Perspective-Shoes_01.png",
  import.meta.url,
)
hero.male.perspective.Shoes_02 = new URL(
  "./img/heroes/Hero_Male_Perspective-Shoes_02.png",
  import.meta.url,
)
hero.male.perspective.Shoes_03 = new URL(
  "./img/heroes/Hero_Male_Perspective-Shoes_03.png",
  import.meta.url,
)
hero.male.perspective.Shoes_04 = new URL(
  "./img/heroes/Hero_Male_Perspective-Shoes_04.png",
  import.meta.url,
)
hero.male.perspective.Shoes_05 = new URL(
  "./img/heroes/Hero_Male_Perspective-Shoes_05.png",
  import.meta.url,
)
hero.male.perspective.Shoes_06 = new URL(
  "./img/heroes/Hero_Male_Perspective-Shoes_06.png",
  import.meta.url,
)
hero.male.perspective.Shoes_07 = new URL(
  "./img/heroes/Hero_Male_Perspective-Shoes_07.png",
  import.meta.url,
)
hero.male.perspective.Shoes_08 = new URL(
  "./img/heroes/Hero_Male_Perspective-Shoes_08.png",
  import.meta.url,
)
hero.male.perspective.Shoes_09 = new URL(
  "./img/heroes/Hero_Male_Perspective-Shoes_09.png",
  import.meta.url,
)
hero.male.perspective.Shoes_10 = new URL(
  "./img/heroes/Hero_Male_Perspective-Shoes_10.png",
  import.meta.url,
)
hero.male.perspective.Skin_01 = new URL(
  "./img/heroes/Hero_Male_Perspective-Skin_01.png",
  import.meta.url,
)
hero.male.perspective.Skin_02 = new URL(
  "./img/heroes/Hero_Male_Perspective-Skin_02.png",
  import.meta.url,
)
hero.male.perspective.Skin_03 = new URL(
  "./img/heroes/Hero_Male_Perspective-Skin_03.png",
  import.meta.url,
)
hero.male.perspective.Skin_04 = new URL(
  "./img/heroes/Hero_Male_Perspective-Skin_04.png",
  import.meta.url,
)
hero.male.perspective.Suit_00 = new URL(
  "./img/heroes/Hero_Male_Perspective-Suit_00.png",
  import.meta.url,
)
hero.male.perspective.Suit_01 = new URL(
  "./img/heroes/Hero_Male_Perspective-Suit_01.png",
  import.meta.url,
)
hero.male.perspective.Suit_02 = new URL(
  "./img/heroes/Hero_Male_Perspective-Suit_02.png",
  import.meta.url,
)
hero.male.perspective.Suit_03 = new URL(
  "./img/heroes/Hero_Male_Perspective-Suit_03.png",
  import.meta.url,
)
hero.male.perspective.Suit_04 = new URL(
  "./img/heroes/Hero_Male_Perspective-Suit_04.png",
  import.meta.url,
)
hero.male.perspective.Suit_05 = new URL(
  "./img/heroes/Hero_Male_Perspective-Suit_05.png",
  import.meta.url,
)
hero.male.perspective.Suit_06 = new URL(
  "./img/heroes/Hero_Male_Perspective-Suit_06.png",
  import.meta.url,
)
hero.male.perspective.Suit_07 = new URL(
  "./img/heroes/Hero_Male_Perspective-Suit_07.png",
  import.meta.url,
)
hero.male.perspective.Suit_08 = new URL(
  "./img/heroes/Hero_Male_Perspective-Suit_08.png",
  import.meta.url,
)
hero.male.perspective.Suit_09 = new URL(
  "./img/heroes/Hero_Male_Perspective-Suit_09.png",
  import.meta.url,
)
hero.male.perspective.Suit_10 = new URL(
  "./img/heroes/Hero_Male_Perspective-Suit_10.png",
  import.meta.url,
)
hero.male.perspective.Accessoire = [
  "Accessoire_01",
  "Accessoire_02",
  "Accessoire_03",
  "Accessoire_04",
  "Accessoire_05",
  "Accessoire_06",
  "Accessoire_07",
  "Accessoire_08",
  "Accessoire_09",
  "Accessoire_10",
]
hero.male.perspective.Cape = [
  "Cape_01",
  "Cape_02",
  "Cape_03",
  "Cape_04",
  "Cape_05",
  "Cape_06",
  "Cape_07",
  "Cape_08",
  "Cape_09",
  "Cape_10",
]
hero.male.perspective.Hair = [
  "Hair_01_Black",
  "Hair_01_Blonde",
  "Hair_01_Blue",
  "Hair_01_Brown",
  "Hair_01_Red",
  "Hair_02_Black",
  "Hair_02_Blonde",
  "Hair_02_Blue",
  "Hair_02_Brown",
  "Hair_02_Red",
  "Hair_03_Black",
  "Hair_03_Blonde",
  "Hair_03_Blue",
  "Hair_03_Brown",
  "Hair_03_Red",
  "Hair_04_Black",
  "Hair_04_Blonde",
  "Hair_04_Blue",
  "Hair_04_Brown",
  "Hair_04_Red",
  "Hair_05_Black",
  "Hair_05_Blonde",
  "Hair_05_Blue",
  "Hair_05_Brown",
  "Hair_05_Red",
]
hero.male.perspective.Lifestyle = [
  "Lifestyle_01",
  "Lifestyle_02",
  "Lifestyle_03",
  "Lifestyle_04",
  "Lifestyle_05",
  "Lifestyle_06",
  "Lifestyle_07",
  "Lifestyle_08",
  "Lifestyle_09",
  "Lifestyle_10",
]
hero.male.perspective.Mask = [
  "Mask_01",
  "Mask_02",
  "Mask_03",
  "Mask_04",
  "Mask_05",
  "Mask_06",
  "Mask_07",
  "Mask_08",
  "Mask_09",
  "Mask_10",
]
hero.male.perspective.Shoes = [
  "Shoes_00",
  "Shoes_01",
  "Shoes_02",
  "Shoes_03",
  "Shoes_04",
  "Shoes_05",
  "Shoes_06",
  "Shoes_07",
  "Shoes_08",
  "Shoes_09",
  "Shoes_10",
]
hero.male.perspective.Skin = ["Skin_01", "Skin_02", "Skin_03", "Skin_04"]
hero.male.perspective.Suit = [
  "Suit_00",
  "Suit_01",
  "Suit_02",
  "Suit_03",
  "Suit_04",
  "Suit_05",
  "Suit_06",
  "Suit_07",
  "Suit_08",
  "Suit_09",
  "Suit_10",
]

hero.overpaints.Accessoire_01 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Accessoire_01.png",
  import.meta.url,
)
hero.overpaints.Accessoire_02 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Accessoire_02.png",
  import.meta.url,
)
hero.overpaints.Accessoire_03 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Accessoire_03.png",
  import.meta.url,
)
hero.overpaints.Accessoire_04 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Accessoire_04.png",
  import.meta.url,
)
hero.overpaints.Accessoire_05 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Accessoire_05.png",
  import.meta.url,
)
hero.overpaints.Accessoire_06 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Accessoire_06.png",
  import.meta.url,
)
hero.overpaints.Accessoire_07 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Accessoire_07.png",
  import.meta.url,
)
hero.overpaints.Accessoire_08 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Accessoire_08.png",
  import.meta.url,
)
hero.overpaints.Accessoire_09 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Accessoire_09.png",
  import.meta.url,
)
hero.overpaints.Accessoire_10 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Accessoire_10.png",
  import.meta.url,
)
hero.overpaints.Cape_01 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Cape_01.png",
  import.meta.url,
)
hero.overpaints.Cape_02 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Cape_02.png",
  import.meta.url,
)
hero.overpaints.Cape_03 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Cape_03.png",
  import.meta.url,
)
hero.overpaints.Cape_04 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Cape_04.png",
  import.meta.url,
)
hero.overpaints.Cape_05 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Cape_05.png",
  import.meta.url,
)
hero.overpaints.Cape_06 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Cape_06.png",
  import.meta.url,
)
hero.overpaints.Cape_07 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Cape_07.png",
  import.meta.url,
)
hero.overpaints.Cape_08 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Cape_08.png",
  import.meta.url,
)
hero.overpaints.Cape_09 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Cape_09.png",
  import.meta.url,
)
hero.overpaints.Cape_10 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Cape_10.png",
  import.meta.url,
)
hero.overpaints.Female_Hair_01 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Female_Hair_01.png",
  import.meta.url,
)
hero.overpaints.Female_Hair_02 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Female_Hair_02.png",
  import.meta.url,
)
hero.overpaints.Female_Hair_03 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Female_Hair_03.png",
  import.meta.url,
)
hero.overpaints.Female_Hair_04 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Female_Hair_04.png",
  import.meta.url,
)
hero.overpaints.Lifestyle_01 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Lifestyle_01.png",
  import.meta.url,
)
hero.overpaints.Lifestyle_02 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Lifestyle_02.png",
  import.meta.url,
)
hero.overpaints.Lifestyle_03 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Lifestyle_03.png",
  import.meta.url,
)
hero.overpaints.Lifestyle_04 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Lifestyle_04.png",
  import.meta.url,
)
hero.overpaints.Lifestyle_05 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Lifestyle_05.png",
  import.meta.url,
)
hero.overpaints.Lifestyle_06 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Lifestyle_06.png",
  import.meta.url,
)
hero.overpaints.Lifestyle_07 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Lifestyle_07.png",
  import.meta.url,
)
hero.overpaints.Lifestyle_08 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Lifestyle_08.png",
  import.meta.url,
)
hero.overpaints.Lifestyle_09 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Lifestyle_09.png",
  import.meta.url,
)
hero.overpaints.Lifestyle_10 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Lifestyle_10.png",
  import.meta.url,
)
hero.overpaints.Male_Hair_01 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Male_Hair_01.png",
  import.meta.url,
)
hero.overpaints.Male_Hair_02 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Male_Hair_02.png",
  import.meta.url,
)
hero.overpaints.Male_Hair_03 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Male_Hair_03.png",
  import.meta.url,
)
hero.overpaints.Male_Hair_04 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Male_Hair_04.png",
  import.meta.url,
)
hero.overpaints.Male_Hair_05 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Male_Hair_05.png",
  import.meta.url,
)
hero.overpaints.Mask_01 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Mask_01.png",
  import.meta.url,
)
hero.overpaints.Mask_02 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Mask_02.png",
  import.meta.url,
)
hero.overpaints.Mask_03 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Mask_03.png",
  import.meta.url,
)
hero.overpaints.Mask_04 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Mask_04.png",
  import.meta.url,
)
hero.overpaints.Mask_05 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Mask_05.png",
  import.meta.url,
)
hero.overpaints.Mask_06 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Mask_06.png",
  import.meta.url,
)
hero.overpaints.Mask_07 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Mask_07.png",
  import.meta.url,
)
hero.overpaints.Mask_08 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Mask_08.png",
  import.meta.url,
)
hero.overpaints.Mask_09 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Mask_09.png",
  import.meta.url,
)
hero.overpaints.Mask_10 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Mask_10.png",
  import.meta.url,
)
hero.overpaints.Shoes_00 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Shoes_00.png",
  import.meta.url,
)
hero.overpaints.Shoes_01 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Shoes_01.png",
  import.meta.url,
)
hero.overpaints.Shoes_02 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Shoes_02.png",
  import.meta.url,
)
hero.overpaints.Shoes_03 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Shoes_03.png",
  import.meta.url,
)
hero.overpaints.Shoes_04 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Shoes_04.png",
  import.meta.url,
)
hero.overpaints.Shoes_05 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Shoes_05.png",
  import.meta.url,
)
hero.overpaints.Shoes_06 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Shoes_06.png",
  import.meta.url,
)
hero.overpaints.Shoes_07 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Shoes_07.png",
  import.meta.url,
)
hero.overpaints.Shoes_08 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Shoes_08.png",
  import.meta.url,
)
hero.overpaints.Shoes_09 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Shoes_09.png",
  import.meta.url,
)
hero.overpaints.Shoes_10 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Shoes_10.png",
  import.meta.url,
)
hero.overpaints.Suit_00 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Suit_00.png",
  import.meta.url,
)
hero.overpaints.Suit_01 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Suit_01.png",
  import.meta.url,
)
hero.overpaints.Suit_02 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Suit_02.png",
  import.meta.url,
)
hero.overpaints.Suit_03 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Suit_03.png",
  import.meta.url,
)
hero.overpaints.Suit_04 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Suit_04.png",
  import.meta.url,
)
hero.overpaints.Suit_05 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Suit_05.png",
  import.meta.url,
)
hero.overpaints.Suit_06 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Suit_06.png",
  import.meta.url,
)
hero.overpaints.Suit_07 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Suit_07.png",
  import.meta.url,
)
hero.overpaints.Suit_08 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Suit_08.png",
  import.meta.url,
)
hero.overpaints.Suit_09 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Suit_09.png",
  import.meta.url,
)
hero.overpaints.Suit_10 = new URL(
  "./img/heroes/overpaints/FMW_Hero_Suit_10.png",
  import.meta.url,
)
