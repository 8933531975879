import "./main.scss"
import { initCardGame } from "./cardGame.js"
import { initScrollToTop } from "./scrollToTop.js"

import { onReady } from "./utils.js"
import { renderApp } from "./world/start.js"

onReady(() => {
  const theWorld = document.querySelector("#the-world")
  theWorld && renderApp(theWorld)
})
onReady(initScrollToTop)
onReady(initCardGame)
