import { useSize } from "ahooks"
import { useEffect, useRef } from "react"

import { clamp, usePersistedState } from "./utils.js"

const scaleMin = 0.25
const scaleMax = 2
const scaleStep = 1.2

export const Pannable = ({ identifier, children, image }) => {
  // Chrome, Edge do not seem to get a size on first page load unfortunately.
  // Use a fallback to avoid crashing when accessing size.* attributes.
  const size = useSize(document.body) || {
    width: document.body.clientWidth,
    height: document.body.clientHeight,
  }
  const [position, setPosition] = usePersistedState(
    `pannable-${identifier}`,
    () => {
      const area = size.width * size.height
      return {
        x: 0.5,
        y: 0.5,
        scale: area > 500000 ? 1 : area > 200000 ? 1.2 : 1.4,
      }
    },
  )

  const pannable = useRef()
  const content = useRef()

  useEffect(() => {
    const pannableRect = pannable.current.getBoundingClientRect()
    const contentRect = content.current.getBoundingClientRect()
    // console.log(pannableRect, contentRect)

    // Maximum translation: Half of the difference
    const maxDX = Math.max(0, (contentRect.width - pannableRect.width) / 2)
    const maxDY = Math.max(0, (contentRect.height - pannableRect.height) / 2)
    // console.log({maxDX, maxDY})

    // Translation: Try centering.
    // 2* because 0.5-position is [-0.5,0.5]
    // *1.2 because overshooting makes for a better behavior at the edges
    let tX = 2 * (0.5 - position.x) * maxDX * 1.2
    let tY = 2 * (0.5 - position.y) * maxDY * 1.2

    // Constrain to maxDX / maxDY
    tX = Math.sign(tX) * Math.min(maxDX, Math.abs(tX))
    tY = Math.sign(tY) * Math.min(maxDY, Math.abs(tY))
    // console.log({ tX, tY })

    content.current.style.transform = `translate3d(${tX}px, ${tY}px, 0)`
  })

  useEffect(() => {
    setTimeout(() => {
      content.current.classList.add("with-transitions")
    }, 100)
  }, [])

  const clickHandler = (e) => {
    if (e.target !== content.current) return

    const cRect = content.current.getBoundingClientRect()
    const x = clamp(0.03, e.nativeEvent.offsetX / cRect.width, 0.97)
    const y = clamp(0.1, e.nativeEvent.offsetY / cRect.height, 0.95)

    setPosition({
      ...position,
      x,
      y,
    })
  }

  return (
    <div className="pannable" ref={pannable} key={identifier}>
      <div
        className={`pannable-content is-${identifier}`}
        ref={content}
        style={{ backgroundImage: `url(${image})`, "--scale": position.scale }}
        onClick={clickHandler}
      >
        {children({ position, setPosition })}
      </div>
      <div className="pannable-controls">
        <button
          type="button"
          className="button button--increase"
          onClick={() =>
            setPosition({
              ...position,
              scale: clamp(scaleMin, position.scale * scaleStep, scaleMax),
            })
          }
        >
          +
        </button>
        <button
          type="button"
          className="button  button--shrink"
          onClick={() =>
            setPosition({
              ...position,
              scale: clamp(scaleMin, position.scale / scaleStep, scaleMax),
            })
          }
        >
          &ndash;
        </button>
      </div>
    </div>
  )
}
