export function initScrollToTop() {
  const top = document.querySelector(".top.hidden")
  if (top && document.body.scrollHeight > 2000) {
    top.classList.remove("hidden")
    top.addEventListener("click", (e) => {
      e.preventDefault()
      const target = document.querySelector("#top")
      target.scrollIntoView({ behavior: "smooth" })
    })
  }
}
