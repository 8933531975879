import shuffle from "@anttikissa/fisher-yates-shuffle"
import { forwardRef, useEffect } from "react"
import withScrolling from "react-dnd-scrolling"

import {
  FMWDndProvider,
  useDragWithEmptyPreview as useDrag,
  useDrop,
} from "../dnd.js"
import { gettext } from "../i18n.js"
import { logger, timestamp } from "../utils.js"

import { reducedExercise, useDispatch } from "./base.js"

import { Fullscreen } from "../fullscreen.js"

const START = "START"
const DROP = "DROP"
const SET_COMMENTARY = "SET_COMMENTARY"

const CARD_TYPE = "Card"

const color = (() => {
  const colors = shuffle([
    "darkgray",
    "ruby",
    "dullgreen",
    "orange",
    "petrolblue",
  ])
  let idx = -1
  return () => `accent-${colors[++idx % colors.length]}`
})()

function initialStateFromProps(props) {
  const first = shuffle(props.pairs).map((pair) => ({
    text: pair.first,
    pairId: pair.id,
    cardId: `${pair.id}-first`,
    color: color(),
  }))
  const second = shuffle(props.pairs).map((pair) => ({
    text: pair.second,
    pairId: pair.id,
    cardId: `${pair.id}-second`,
    color: color(),
    slotId: null,
  }))

  return {
    first,
    second,
    requireCommentary: props.requireCommentary,
    commentaryQuestion: props.commentaryQuestion,
    commentary: "",
    startedExerciseAt: timestamp(),
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case START:
      return { ...state, startedInteractiveAt: timestamp() }

    case DROP: {
      const second = state.second.map((card) => {
        if (action.cardId === card.cardId) {
          return { ...card, slotId: action.slotId }
        }
        if (action.slotId === card.slotId) {
          return { ...card, slotId: null }
        }
        return card
      })
      return {
        ...state,
        second,
      }
    }

    case SET_COMMENTARY:
      return {
        ...state,
        commentary: action.commentary,
      }

    default:
      throw new Error(`Unknown action ${action}`)
  }
}

const slottedCards = (state) => {
  const slots = Array.from({ length: state.first.length })
  for (const card of state.second) {
    if (card.slotId !== null) slots[card.slotId] = card
  }
  return slots
}
const isCompleted = (slots) => slots.every((card) => !!card)
const binnedCards = (state) =>
  state.second.filter((card) => card.slotId === null)

function saveState(state) {
  const slots = slottedCards(state)
  return {
    durationInteractive: state.finishedAt - state.startedInteractiveAt,
    durationExercise: state.finishedAt - state.startedExerciseAt,
    pairs: state.first.map((card, idx) => [card.pairId, slots[idx].pairId]),
    commentaryQuestion: state.commentaryQuestion,
    commentary: state.commentary,
  }
}

function Commentary({ state }) {
  const dispatch = useDispatch()

  if (!state.requireCommentary) return null

  return (
    <div className="a-text__question">
      <label htmlFor="commentary">{state.commentaryQuestion}</label>
      <div className="grow-wrap" data-replicated-value={state.commentary}>
        <textarea
          id="commentary"
          value={state.commentary}
          onChange={(e) =>
            dispatch({
              type: SET_COMMENTARY,
              commentary: e.target.value,
            })
          }
        />
      </div>
    </div>
  )
}

const ScrollingComponent = withScrolling("div")

export function TextPuzzleImpl({ state, setSaveState, isCurrent }) {
  const dispatch = useDispatch()

  console.log("In TextPuzzleImpl", state)

  useEffect(() => {
    if (!state.startedInteractiveAt && isCurrent) {
      setTimeout(() => dispatch({ type: START }), 1000)
    }
  }, [state.startedInteractiveAt, dispatch, isCurrent])

  const slots = slottedCards(state)

  return (
    <Fullscreen allowFullscreen={!state.finishedAt}>
      <FMWDndProvider Preview={CardPreview}>
        <div className="a-textpuzzle">
          <ScrollingComponent className="exercise__scrolling-container">
            <div className="a-textpuzzle__pairs">
              {state.first.map((card, idx) => (
                <div
                  key={card.cardId}
                  className={`a-textpuzzle__pair ${card.color}`}
                >
                  <Card card={card} className="a-textpuzzle__first-text" />
                  <Slot card={slots[idx]} slot={idx} />
                </div>
              ))}
            </div>
          </ScrollingComponent>
          <ScrollingComponent className="exercise__scrolling-container">
            <Bin cards={binnedCards(state)} />
          </ScrollingComponent>
        </div>
        <Commentary state={state} />
      </FMWDndProvider>
      <button
        type="button"
        className="button button--save"
        disabled={!isCompleted(slots)}
        onClick={() => {
          if (document.fullscreenElement) {
            document.exitFullscreen()
          }
          setSaveState(saveState({ finishedAt: timestamp(), ...state }))
        }}
      >
        {gettext("Save")}
      </button>
    </Fullscreen>
  )
}

const Card = forwardRef(({ card, className = "" }, ref) => (
  <div className={`a-textpuzzle__card ${className}`} ref={ref}>
    {card.text}
  </div>
))

const CardPreview = ({ item, translate }) => {
  return (
    <div
      style={{
        position: "absolute",
        transform: translate,
        margin: "-20px 0 0 -20px",
      }}
    >
      <Card card={item.card} className="a-textpuzzle__preview" />
    </div>
  )
}

function DraggableCard({ card }) {
  const [_collectedProps, drag] = useDrag({
    type: CARD_TYPE,
    item: { card },
  })

  return <Card card={card} ref={drag} />
}

function Slot({ card, slot }) {
  const dispatch = useDispatch()
  const [{ isOver }, drop] = useDrop({
    accept: CARD_TYPE,
    collect: (monitor) => ({ isOver: monitor.isOver() }),
    drop: (item, _monitor) => {
      dispatch({ type: DROP, cardId: item.card.cardId, slotId: slot })
    },
  })

  return (
    <div
      className={`a-textpuzzle__slot ${card ? "has-card" : ""} ${
        isOver ? "is-over" : ""
      }`}
      ref={drop}
    >
      {card ? <DraggableCard card={card} /> : null}
    </div>
  )
}

function Bin({ cards }) {
  const dispatch = useDispatch()
  const [{ isOver }, drop] = useDrop({
    accept: CARD_TYPE,
    collect: (monitor) => ({ isOver: monitor.isOver() }),
    drop: (item, _monitor) => {
      dispatch({
        type: DROP,
        cardId: item.card.cardId,
        slotId: null,
      })
    },
  })

  return (
    <div className={`a-textpuzzle__bin ${isOver ? "is-over" : ""}`} ref={drop}>
      {cards.map((card) => (
        <DraggableCard key={card.cardId} card={card} />
      ))}
    </div>
  )
}

export const TextPuzzle = reducedExercise({
  reducer: logger("TextPuzzle: ", reducer),
  initialStateFromProps,
  Implementation: TextPuzzleImpl,
})
