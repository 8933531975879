import { useEffect } from "react"

import { gettext } from "../i18n.js"
import { clamp, logger, sleep, timestamp } from "../utils.js"

import { reducedExercise, useDispatch } from "./base.js"

const currentQuestion = (state) => state.questions[state.currentIndex]

const START = "START"
const GO_ABSOLUTE = "GO_ABSOLUTE"
const GO_RELATIVE = "GO_RELATIVE"
const CHECK = "CHECK"

function initialStateFromProps(props) {
  return {
    questions: props.questions,
    currentIndex: 0,
    checkedAnswers: {},
    startedExerciseAt: timestamp(),
  }
}

const isCompleted = (state) =>
  state.currentIndex === state.questions.length - 1 &&
  state.questions.every((question) => state.checkedAnswers[question.id])

const reducer = (state, action) => {
  switch (action.type) {
    case START:
      return { ...state, startedInteractiveAt: timestamp() }

    case GO_ABSOLUTE:
      return {
        ...state,
        currentIndex: clamp(0, action.index, state.questions.length - 1),
      }

    case GO_RELATIVE:
      return {
        ...state,
        currentIndex: clamp(
          0,
          state.currentIndex + action.delta,
          state.questions.length - 1,
        ),
      }

    case CHECK:
      return {
        ...state,
        checkedAnswers: {
          ...state.checkedAnswers,
          [action.question]: action.answer,
        },
      }

    default:
      throw new Error(`Unknown action ${action}`)
  }
}

const saveState = (state) => {
  return {
    mode: "quiz",
    durationInteractive: state.finishedAt - state.startedInteractiveAt,
    durationExercise: state.finishedAt - state.startedExerciseAt,
    checkedAnswers: state.checkedAnswers,
  }
}

export function QuizImpl({ state, setSaveState, isCurrent }) {
  const question = currentQuestion(state)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!state.startedInteractiveAt && isCurrent) {
      setTimeout(() => dispatch({ type: START }), 1000)
    }
  }, [state.startedInteractiveAt, dispatch, isCurrent])

  if (!question) {
    return <p className="error">{gettext("No questions defined?")}</p>
  }

  return (
    <div className="a-multiplechoice">
      <Question
        question={question}
        checkedAnswers={state.checkedAnswers}
        checkAnswer={async (answer) => {
          dispatch({
            type: CHECK,
            question: question.id,
            answer,
          })
          await sleep(250)
          dispatch({ type: GO_ABSOLUTE, index: state.currentIndex + 1 })
        }}
      />
      <div className="a-multiplechoice__buttongroup">
        <button
          className="button"
          type="button"
          disabled={state.currentIndex === 0}
          onClick={() => dispatch({ type: GO_RELATIVE, delta: -1 })}
        >
          {gettext("Previous")}
        </button>
        <button
          className="button"
          type="button"
          disabled={
            state.currentIndex === state.questions.length - 1 ||
            !state.checkedAnswers[question.id]
          }
          onClick={() => dispatch({ type: GO_RELATIVE, delta: 1 })}
        >
          {gettext("Next")}
        </button>
        <button
          type="button"
          className="button"
          disabled={!isCompleted(state)}
          onClick={() =>
            setSaveState(saveState({ finishedAt: timestamp(), ...state }))
          }
        >
          {gettext("Save")}
        </button>
      </div>
    </div>
  )
}

function Question({ question, checkedAnswers, checkAnswer }) {
  return (
    <div className="a-multiplechoice__gameboard a-multiplechoice__gameboard--quiz">
      <div
        className="a-multiplechoice__question"
        dangerouslySetInnerHTML={{ __html: question.question }}
      />
      <div className="a-multiplechoice__answers">
        {question.answers.map(({ id, answer }) => (
          <label
            key={id}
            className="a-multiplechoice__answer a-multiplechoice__answer--quiz"
          >
            <input
              name={`question-${question.id}`}
              type="radio"
              className="a-multiplechoice__checkbox a-multiplechoice__checkbox--radio"
              checked={checkedAnswers[question.id] === id}
              onChange={() => checkAnswer(id)}
            />
            <div className="a-multiplechoice__answer-text a-multiplechoice__answer-text--quiz">
              <div className="a-multiplechoice__answer-text-inner">
                {answer}
              </div>
            </div>
          </label>
        ))}
      </div>
    </div>
  )
}

export const Quiz = reducedExercise({
  reducer: logger("Quiz: ", reducer),
  initialStateFromProps,
  Implementation: QuizImpl,
})
