import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"

import { hero } from "./assets.js"
import { fetchJSON } from "./fetch.js"
import { Hero } from "./heroes.js"
import { gettext, interpolate } from "./i18n.js"
import { randomChoice, useSettings } from "./utils.js"
import { useWorldActions, useWorldState } from "./world.js"

const Myself = ({ hairStyle }) => {
  const { user } = useWorldState()

  let items = user.equippedItems
  if (hairStyle) {
    items = [...items.filter((item) => !item.includes("Hair")), hairStyle]
  }

  return (
    <>
      <div className="profile__hero">
        <Hero
          className="hero is-profile"
          assetGroup={hero[user.sex].perspective}
          items={items}
        />
        <h1 className="profile__title">{user.heroName}</h1>
      </div>
    </>
  )
}

const HairStyles = ({ setHairStyle, hairStyle }) => {
  const { user } = useWorldState()
  const { updateWorld } = useWorldActions()
  const { urls } = useSettings()

  if (user.bucks < 3) return null

  return (
    <>
      <h2 className="shop__title">{gettext("Hero Hair Salon")}</h2>
      <div className="shop__items shop__items--hairstyle">
        <div className="shop__item__wrap">
          <div
            className="button shop__item shop__item--hairstyle"
            onClick={() => {
              setHairStyle(randomChoice(hero[user.sex].perspective.Hair))
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="shop__item-scissors"
              viewBox="0 0 16 16"
            >
              <defs>
                <linearGradient id="shop__item-scissors-gradient">
                  <stop offset="0%" stopColor="var(--color-stop)" />
                  <stop offset="100%" stopColor="var(--color-bot)" />
                </linearGradient>
              </defs>
              <path d="M3.5 3.5c-.614-.884-.074-1.962.858-2.5L8 7.226 11.642 1c.932.538 1.472 1.616.858 2.5L8.81 8.61l1.556 2.661a2.5 2.5 0 1 1-.794.637L8 9.73l-1.572 2.177a2.5 2.5 0 1 1-.794-.637L7.19 8.61 3.5 3.5zm2.5 10a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0zm7 0a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z" />
            </svg>

            <div className="shop__item--hairstyle-text">
              {gettext("New hair style")}
            </div>
          </div>

          <div className="shop__item--hairstyle-price">
            <button
              type="button"
              className="button button--hairstyle-price"
              onClick={async () => {
                const data = await buyHairStyle(urls, hairStyle)
                data?.world && updateWorld(data.world)
                setHairStyle(null)
              }}
              disabled={!hairStyle}
            >
              {gettext("Buy (3 Bucks)")}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

function buyHairStyle(urls, identifier) {
  const body = new FormData()
  body.append("identifier", identifier)
  return fetchJSON(urls.buyHairStyle, {
    method: "POST",
    body,
  })
}

function equipItem(urls, identifier) {
  const body = new FormData()
  body.append("identifier", identifier)
  return fetchJSON(urls.equip, {
    method: "POST",
    body,
  })
}

function unequipItem(urls, identifier) {
  const body = new FormData()
  body.append("identifier", identifier)
  return fetchJSON(urls.unequip, {
    method: "POST",
    body,
  })
}

const OwnedItems = ({ selectedType }) => {
  const { ownedItems, user } = useWorldState()
  const { updateWorld } = useWorldActions()
  const { urls } = useSettings()

  return (
    <>
      <h2 className="shop__title">{gettext("Owned items")}</h2>
      <div className="shop__items">
        {ownedItems
          .filter(
            (item) => !selectedType || selectedType.name === item.typeName,
          )
          .map((item) =>
            /^(Hair|Skin)/.test(item.identifier) ? null : (
              <a
                key={item.identifier}
                className={`shop__item ${
                  user.equippedItems.includes(item.identifier)
                    ? "is-equipped"
                    : ""
                }`}
                href={`#${item.identifier}`}
                onClick={async (e) => {
                  e.preventDefault()
                  let data
                  if (
                    user.equippedItems.includes(item.identifier) &&
                    !/^(Suit|Shoes|Hair|Skin)/.test(item.identifier)
                  ) {
                    data = await unequipItem(urls, item.identifier)
                  } else {
                    data = await equipItem(urls, item.identifier)
                  }
                  data?.world && updateWorld(data.world)
                }}
              >
                <img src={hero.overpaints[item.identifier]} alt="" />
                <span className="shop__item-name">{item.name}</span>
              </a>
            ),
          )}
      </div>
    </>
  )
}

const BuyableItems = ({ buyableItems, setBuyableItems, selectedType }) => {
  const { urls } = useSettings()
  const { updateWorld } = useWorldActions()

  const items = buyableItems.filter(
    (item) => !selectedType || selectedType.name === item.typeName,
  )

  return (
    <>
      <h2 className="shop__title">{gettext("Buyable items")}</h2>
      <div className="shop__items shop__items--buyable">
        {items.map((item) => (
          <a
            key={item.identifier}
            className="shop__item"
            href={`#${item.identifier}`}
            onClick={async (e) => {
              e.preventDefault()
              const body = new FormData()
              body.append("identifier", item.identifier)
              const { world, buyableItems } = await fetchJSON(urls.buy, {
                method: "POST",
                body,
              })

              world && updateWorld(world)
              setBuyableItems(buyableItems)
            }}
          >
            <img src={hero.overpaints[item.identifier]} alt="" />
            <span className="shop__item-name">{item.name}</span>
            <span className="shop__item-price">
              {interpolate(gettext("%(price)s bucks"), item)}
            </span>
          </a>
        ))}
      </div>
    </>
  )
}

export function Profile({ closeProfile }) {
  const { urls } = useSettings()
  const [hairStyle, setHairStyle] = useState(null)
  const [buyableItems, setBuyableItems] = useState([])
  const [types, setTypes] = useState(null)
  const [selectedType, setSelectedType] = useState(null)

  useEffect(() => {
    const doFetch = async () => {
      const { buyableItems, types } = await fetchJSON(urls.buyableItems)
      setBuyableItems(buyableItems)
      setTypes(types)
    }

    doFetch()
  }, [urls])

  return (
    <div className="overlay is-profile">
      <button
        type="button"
        className="button button--profile"
        onClick={closeProfile}
      >
        &lt; {gettext("Back to the apps")}
      </button>
      <div className="profile apps__container">
        <Helmet>
          <title>{gettext("Profile")} - FinanceMission World</title>
        </Helmet>

        <div className="profile__shop">
          <div className="shop__categories grouped-buttons">
            <button
              type="button"
              className={`button ${!selectedType ? "button--current" : ""}`}
              onClick={() => setSelectedType(null)}
            >
              {gettext("All")}
            </button>

            {types?.map((t) => (
              <button
                type="button"
                className={`button ${
                  selectedType === t ? "button--current" : ""
                }`}
                onClick={() => setSelectedType(t)}
                key={t.identifier}
              >
                {t.name}
              </button>
            ))}
          </div>
          <OwnedItems selectedType={selectedType} />
          <BuyableItems
            buyableItems={buyableItems}
            setBuyableItems={setBuyableItems}
            selectedType={selectedType}
          />
          <HairStyles setHairStyle={setHairStyle} hairStyle={hairStyle} />
        </div>

        <div className="profile__hero-container">
          <Myself hairStyle={hairStyle} />
        </div>
      </div>
    </div>
  )
}
