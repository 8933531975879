import { useEffect } from "react"

import { gettext } from "../i18n.js"
import { clamp, logger, timestamp } from "../utils.js"

import { reducedExercise, useDispatch } from "./base.js"

const currentQuestion = (state) => state.questions[state.currentIndex]

const START = "START"
const GO_ABSOLUTE = "GO_ABSOLUTE"
const GO_RELATIVE = "GO_RELATIVE"
const CHECK = "CHECK"

function initialStateFromProps(props) {
  return {
    questions: props.questions,
    currentIndex: 0,
    checkedAnswers: {},
    startedExerciseAt: timestamp(),
  }
}

const isCompleted = (state) => state.currentIndex === state.questions.length - 1

const reducer = (state, action) => {
  switch (action.type) {
    case START:
      return { ...state, startedInteractiveAt: timestamp() }

    case GO_ABSOLUTE:
      return {
        ...state,
        currentIndex: clamp(0, action.index, state.questions.length - 1),
      }

    case GO_RELATIVE:
      return {
        ...state,
        currentIndex: clamp(
          0,
          state.currentIndex + action.delta,
          state.questions.length - 1,
        ),
      }

    case CHECK:
      return {
        ...state,
        checkedAnswers: {
          ...state.checkedAnswers,
          [action.answer]: action.checked,
        },
      }

    default:
      throw new Error(`Unknown action ${action}`)
  }
}

const saveState = (state) => {
  return {
    mode: "multipleChoice",
    durationInteractive: state.finishedAt - state.startedInteractiveAt,
    durationExercise: state.finishedAt - state.startedExerciseAt,
    checkedAnswers: state.checkedAnswers,
  }
}

export function MultipleChoiceImpl({ state, setSaveState, isCurrent }) {
  const question = currentQuestion(state)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!state.startedInteractiveAt && isCurrent) {
      setTimeout(() => dispatch({ type: START }), 1000)
    }
  }, [state.startedInteractiveAt, dispatch, isCurrent])

  console.log(state)

  if (!question) {
    return <p className="error">{gettext("No questions defined?")}</p>
  }

  return (
    <div className="a-multiplechoice">
      <Question question={question} checkedAnswers={state.checkedAnswers} />

      <div className="a-multiplechoice__buttongroup">
        <button
          className="button"
          type="button"
          disabled={state.currentIndex === 0}
          onClick={() => dispatch({ type: GO_RELATIVE, delta: -1 })}
        >
          {gettext("Previous")}
        </button>
        <button
          className="button"
          type="button"
          disabled={state.currentIndex === state.questions.length - 1}
          onClick={() => dispatch({ type: GO_RELATIVE, delta: 1 })}
        >
          {gettext("Next")}
        </button>
        <button
          type="button"
          className="button"
          disabled={!isCompleted(state)}
          onClick={() =>
            setSaveState(saveState({ finishedAt: timestamp(), ...state }))
          }
        >
          {gettext("Save")}
        </button>
      </div>
    </div>
  )
}

function Question({ question, checkedAnswers }) {
  const dispatch = useDispatch()

  return (
    <div className="a-multiplechoice__gameboard">
      <div
        className="a-multiplechoice__question"
        dangerouslySetInnerHTML={{ __html: question.question }}
      />
      <div className="a-multiplechoice__answers">
        {question.answers.map(({ id, answer }) => (
          <label key={id} className="a-multiplechoice__answer">
            <input
              type="checkbox"
              className="a-multiplechoice__checkbox"
              checked={checkedAnswers[id] || false}
              onChange={(e) =>
                dispatch({
                  type: CHECK,
                  answer: id,
                  checked: e.target.checked,
                })
              }
            />
            <div className="a-multiplechoice__answer-text">
              <div className="a-multiplechoice__answer-text-inner">
                {answer}
              </div>
            </div>
          </label>
        ))}
      </div>
    </div>
  )
}

export const MultipleChoice = reducedExercise({
  reducer: logger("MultipleChoice: ", reducer),
  initialStateFromProps,
  Implementation: MultipleChoiceImpl,
})
