import fscreen from "fscreen"
import { useCallback, useEffect, useRef, useState } from "react"
import { gettext } from "./i18n.js"

export function Fullscreen({ children, allowFullscreen }) {
  const [inFullscreenMode, setInFullscreenMode] = useState(false)

  const handleFullscreenChange = useCallback((e) => {
    let change = ""
    if (fscreen.fullscreenElement !== null) {
      change = "Entered fullscreen mode"
      setInFullscreenMode(true)
    } else {
      change = "Exited fullscreen mode"
      setInFullscreenMode(false)
    }
    console.log(change, e)
  }, [])

  const handleFullscreenError = useCallback((e) => {
    console.error("Fullscreen Error", e)
  }, [])

  useEffect(() => {
    if (fscreen.fullscreenEnabled) {
      fscreen.addEventListener(
        "fullscreenchange",
        handleFullscreenChange,
        false,
      )

      fscreen.addEventListener("fullscreenerror", handleFullscreenError, false)

      return () => {
        fscreen.removeEventListener("fullscreenchange", handleFullscreenChange)
        fscreen.removeEventListener("fullscreenerror", handleFullscreenError)
      }
    }
  })

  useEffect(() => {
    if (!allowFullscreen && inFullscreenMode) {
      fscreen.exitFullscreen()
    }
  })

  const appElement = useRef(null)

  const toggleFullscreen = useCallback(() => {
    if (inFullscreenMode) {
      fscreen.exitFullscreen()
    } else {
      fscreen.requestFullscreen(appElement.current)
    }
  }, [inFullscreenMode])

  if (!fscreen.fullscreenEnabled) {
    console.info("HTML5 fullscreen is not available")
    return children
  }

  return (
    <div
      className={`fullscreen ${inFullscreenMode ? "is-fullscreen" : ""}`}
      ref={appElement}
    >
      {children}

      <button
        type="button"
        className="button fullscreen__toggle"
        onClick={toggleFullscreen}
        title={
          (inFullscreenMode && gettext("Exit fullscreen mode")) ||
          gettext("Enter fullscreen mode")
        }
      >
        {inFullscreenMode ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z" />
          </svg>
        )}
      </button>
    </div>
  )
}
