import { useEffect, useState } from "react"
import { gettext } from "../i18n.js"
import { logger, timestamp } from "../utils.js"

import { reducedExercise } from "./base.js"

const initialStateFromProps = () => ({
  puzzleIndex: 0,
  startedExerciseAt: timestamp(),
})

const reducer = (_state, action) => {
  switch (action.type) {
    default:
      throw new Error(`Unknown action ${action}`)
  }
}

const videoIDs = { de: 580286431, fr: 652373477, it: 652372792 }
const Player = () => {
  const languageCode = document.documentElement.lang
  const id = videoIDs[languageCode] || videoIDs.de
  return (
    <div className="responsive-embed widescreen vimeo">
      <iframe
        src={`https://player.vimeo.com/video/${id}?byline=0&portrait=0`}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen=""
      />
    </div>
  )
}

const saveState = (state) => ({
  isWin: true,
  durationExercise: timestamp() - state.startedExerciseAt,
})

function VideoImpl({ state, setSaveState }) {
  const [disableSave, setDisableSave] = useState(true)
  useEffect(() => {
    const timeout = setTimeout(() => setDisableSave(false), 60 * 1000)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <div className="video">
      <Player />
      <br />
      <div className="buttons buttons--center">
        <button
          type="button"
          className="button"
          disabled={disableSave}
          onClick={() => setSaveState(saveState(state))}
        >
          {gettext("Thanks, I have watched the video")}
        </button>
      </div>
    </div>
  )
}

export const Video = reducedExercise({
  reducer: logger("[hlc-video] ", reducer),
  initialStateFromProps,
  Implementation: VideoImpl,
})
