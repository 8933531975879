import { useEffect, useRef, useState } from "react"

import { gettext } from "../i18n.js"
import { intersect } from "../utils.js"
import { useWorldState } from "../world.js"

const randInt = (min, max) => Math.round(min + Math.random() * (max - min))

const Comic = ({ identifier, panels }) => {
  const [isPile, setIsPile] = useState(true)
  const ref = useRef()

  const style = () => {
    return isPile
      ? {
          left: `${randInt(5, 15)}%`,
          top: `${randInt(5, 15)}%`,
          transform: `scale(0.5) rotate(${randInt(-540, 540)}deg)`,
        }
      : {}
  }

  useEffect(() => {
    setTimeout(ref.current.classList.add("with-transitions"), 10)
  }, [ref])

  return (
    <div
      className={`comic comic--${identifier}`}
      onClick={() => setIsPile(!isPile)}
      ref={ref}
    >
      {panels.map((panel) => (
        <div
          key={panel}
          className="comic__item"
          data-item={panel}
          style={style()}
        />
      ))}
      {isPile && panels.length ? (
        <button type="button" className="button comic__button">
          {gettext("Sort the pile")}
        </button>
      ) : null}
    </div>
  )
}

export const Resolution = ({ district, goToDistrict }) => {
  const { user } = useWorldState()
  const allPanels = district.resolutionPanels
  const awardedPanels = intersect(
    district.resolutionPanels,
    user.resolutionPanels,
  )

  return (
    <div className="exercise exercise--resolution">
      <div className="exercise-screen is-interactive">
        <h1>
          {gettext("Resolution")}{" "}
          <small>
            {awardedPanels.length} / {allPanels.length}{" "}
            {gettext("panels awarded")}
          </small>
        </h1>
        <button
          type="button"
          className="button button--close"
          onClick={goToDistrict}
        >
          {gettext("Close")}
        </button>
        <Comic identifier={district.district} panels={awardedPanels} />
      </div>
    </div>
  )
}
