import { createContext, useContext, useMemo, useReducer } from "react"
import { useInitialData } from "./utils.js"

const UPDATE_WORLD = "UPDATE_WORLD"

const updateWorld = (dispatch) => (world) =>
  dispatch({ type: UPDATE_WORLD, world })

const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_WORLD:
      return { ...state, ...action.world }
    default:
      throw new Error(`Unknown action ${action}`)
  }
}

const WorldContext = createContext()
export const useWorldState = () => useContext(WorldContext).state
export const useWorldActions = () => useContext(WorldContext).actions

export function WorldData({ children }) {
  const [state, dispatch] = useReducer(reducer, useInitialData("initial-world"))

  // console.log({ state })

  const actions = useMemo(
    () => ({
      updateWorld: updateWorld(dispatch),
    }),
    [],
  )

  return (
    <WorldContext.Provider value={{ state, actions }}>
      {children}
    </WorldContext.Provider>
  )
}
