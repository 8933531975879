import { useThrottleEffect } from "ahooks"
import { useMemo, useReducer, useState } from "react"

export const clamp = (min, now, max) => Math.max(min, Math.min(max, now))
export const randomIndex = (items) => Math.floor(Math.random() * items.length)
export const randomChoice = (items) => items[randomIndex(items)]
export const timestamp = () => new Date().getTime() / 1000

const VERSION = 1

const loadFromLocalStorage = (storageKey) => {
  try {
    const persisted = JSON.parse(localStorage.getItem(storageKey))
    if (
      persisted.version === VERSION &&
      timestamp() - persisted.modifiedAt < 30 * 86400
    )
      return persisted.data
  } catch (_err) {
    /* Intentionally left empty */
  }
  return null
}

const saveToLocalStorage = (storageKey, state) => {
  if (state._skipSave) return
  localStorage.setItem(
    storageKey,
    JSON.stringify({ modifiedAt: timestamp(), version: VERSION, data: state }),
  )
}

/**
 * useReducer with automatic localStorage persistence
 *
 * From https://stackoverflow.com/a/54474945
 */
export function usePersistedReducer(
  storageKey,
  reducer,
  defaultState,
  init = null,
) {
  const [state, dispatch] = useReducer(
    reducer,
    defaultState,
    (defaultState) => {
      const persisted = loadFromLocalStorage(storageKey)
      return persisted !== null
        ? persisted
        : init !== null
          ? init(defaultState)
          : defaultState
    },
  )

  useThrottleEffect(() => {
    saveToLocalStorage(storageKey, state)
  }, [storageKey, state])

  return [state, dispatch]
}

export function usePersistedState(storageKey, initialState) {
  const [state, setState] = useState(() => {
    const persisted = loadFromLocalStorage(storageKey)
    return persisted !== null
      ? persisted
      : typeof initialState === "function"
        ? initialState()
        : initialState
  })

  useThrottleEffect(() => {
    saveToLocalStorage(storageKey, state)
  }, [storageKey, state])

  return [state, setState]
}

/**
 * Sleep inside async functions for a bit.
 *
 * await sleep(500) // Sleeps 500ms
 */
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * Wrap a reducer to log all actions happening inside
 */
export const logger = (prefix, reducer) => (state, action) => {
  console.group(`${prefix}Reducer action`)
  console.log(`${prefix}Action`, action)
  state = reducer(state, action)
  console.log(`${prefix}New state`, state)
  console.groupEnd()
  return state
}

export const useInitialData = (id) =>
  useMemo(() => {
    const el = document.getElementById(id)
    return JSON.parse(el.textContent)
  }, [id])

export const useSettings = () => useInitialData("settings")

export const intersect = (arr1, arr2) => arr1.filter((el) => arr2.includes(el))
