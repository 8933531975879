export function csrfTokenHeader() {
  return {
    "X-CSRFToken": document.cookie.match(/\bcsrftoken=(.+?)\b/)[1],
  }
}

export const containsJSON = (response) => {
  const contentType = response.headers.get("content-type")
  return contentType ? contentType.includes("application/json") : false
}

export function getCookie(cookieName) {
  const cookies = document.cookie ? document.cookie.split("; ") : []
  const prefix = `${cookieName}=`
  for (const cookie of cookies) {
    if (cookie.startsWith(prefix))
      return decodeURIComponent(cookie.substring(prefix.length))
  }
}

export const fetchAPI = async (url, opts = {}) => {
  opts.credentials = "same-origin"
  opts.headers = opts.headers || {}
  opts.headers["X-CSRFToken"] = getCookie("csrftoken")
  const response = await fetch(url, opts)
  if (response.status === 401) {
    window.location.href = `/world/login/?next=${encodeURIComponent(
      window.location.pathname,
    )}`
  }
  return response
}

export const fetchJSON = async (url, opts) => {
  const response = await fetchAPI(url, opts)
  if (!response.ok || !containsJSON(response))
    throw new Error(response.statusText)
  return response.json()
}
