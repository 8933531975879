import { useEffect } from "react"

import { gettext, interpolate } from "../i18n.js"
import { logger, timestamp } from "../utils.js"

import { reducedExercise, useDispatch } from "./base.js"

function initialStateFromProps({ fields }) {
  console.log("initialStateFromProps called")
  return {
    fieldIds: fields.map((field) => field.id),
    fieldsById: Object.fromEntries(fields.map((field) => [field.id, field])),
    values: {},
    startedInteractiveAt: null,
    startedExerciseAt: timestamp(),
  }
}

function countWords(str) {
  const matches = str.match(/[\p{Letter}\p{Number}]+/giu)
  // console.log({ str, matches })
  return matches ? matches.length : 0
}

const fieldsWithValues = (state) =>
  state.fieldIds.map((id) => {
    const field = state.fieldsById[id]
    const value = state.values[id] || ""
    const words = countWords(value)

    return { field, value, words, isValid: words >= field.minWords }
  })

/*
const allValid = (fieldsWithValues) =>
  fieldsWithValues.reduce(
    (acc, currentValue) => acc && currentValue.isValid,
    true
  )
*/

const START = "START"
const UPDATE = "UPDATE"

const reducer = (state, action) => {
  if (!state.startedInteractiveAt) {
    state = { ...state, startedInteractiveAt: timestamp() }
  }

  switch (action.type) {
    case START:
      // The relevant update already happened above
      return state

    case UPDATE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }

    default:
      throw new Error(`Unknown action ${action}`)
  }
}

function TextImpl({ state, setSaveState, isCurrent }) {
  const fieldsWV = fieldsWithValues(state)
  const allValid = fieldsWV.every(({ isValid }) => isValid)

  const saveState = () => ({
    fields: fieldsWV.map((field) => ({
      value: field.value,
      ...field.field,
    })),
    durationInteractive: timestamp() - state.startedInteractiveAt,
    durationExercise: timestamp() - state.startedExerciseAt,
  })

  const dispatch = useDispatch()

  useEffect(() => {
    if (isCurrent && !state.startedInteractiveAt) {
      dispatch({ type: START })
    }
  })

  console.log({ state })

  return (
    <div className="a-text">
      {fieldsWV.map((field) => {
        const id = `ta-${field.field.id}`
        return (
          <div
            key={id}
            className={`a-text__question ${
              field.value && !field.isValid ? "is-invalid" : ""
            }`}
          >
            <label>
              {field.field.question} (
              {interpolate(
                gettext("%(current)s of at least %(required)s words"),
                { current: field.words, required: field.field.minWords },
              )}
              )
            </label>
            <Input
              value={field.value}
              onChange={(e) =>
                dispatch({
                  type: UPDATE,
                  id: field.field.id,
                  value: e.target.value,
                })
              }
            />
          </div>
        )
      })}
      <button
        type="button"
        className="button"
        disabled={!allValid}
        onClick={() => setSaveState(saveState())}
      >
        {gettext("Save")}
      </button>
    </div>
  )
}

const Input = ({ value, onChange }) => {
  return (
    <div className="grow-wrap" data-replicated-value={value}>
      <textarea value={value} onChange={onChange} />
    </div>
  )
}

export const Text = reducedExercise({
  reducer: logger("Text: ", reducer),
  initialStateFromProps,
  Implementation: TextImpl,
})
