import { useEffect } from "react"

import { HTML5toTouch } from "rdndmb-html5-to-touch"
import { useDrag, useDragLayer } from "react-dnd"
import { getEmptyImage } from "react-dnd-html5-backend"
import { DndProvider } from "react-dnd-multi-backend"

export { useDrag, useDrop } from "react-dnd"

const layerStyles = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 100,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
}

const blackRect =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABYSURBVHgB7dghDgAgDATBg/D/L0MwCOyaEzuubpNWdSTZKTZTrj5wffNIh3d2rpgykDKQMpAykDKQMpAykDKQMpAykDKQMpAykDKQMpCqD7wfVZ/oRH3gAcyvA09rskUQAAAAAElFTkSuQmCC"

const BlackRectPreview = ({ translate }) => (
  <img
    src={blackRect}
    style={{
      transform: translate,
      position: "absolute",
      width: "40px",
      height: "40px",
      margin: "-20px 0 0 -20px",
      padding: "0",
    }}
    alt=""
  />
)

const FMWDragLayer = ({ Preview }) => {
  const { isDragging, position, item } = useDragLayer((monitor) => {
    const wrap = document
      .querySelector(".exercise-screen.is-interactive")
      ?.getBoundingClientRect()
    const pos = monitor.getClientOffset()
    return {
      isDragging: monitor.isDragging(),
      position: wrap && pos ? { x: pos.x - wrap.x, y: pos.y - wrap.y } : null,
      item: monitor.getItem(),
    }
  })

  if (!isDragging || !position) return null

  return (
    <div style={layerStyles}>
      <Preview
        item={item}
        position={position}
        translate={`translate(${position.x}px, ${position.y}px)`}
      />
    </div>
  )
}

export const FMWDndProvider = ({ children, Preview = BlackRectPreview }) => {
  return (
    <DndProvider options={HTML5toTouch}>
      {children}
      <FMWDragLayer Preview={Preview} />
    </DndProvider>
  )
}

export const useDragWithEmptyPreview = (config) => {
  const [collectedProps, drag, preview] = useDrag(config)
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])
  return [collectedProps, drag, preview]
}
