import { lazy } from "react"

import { OddOne } from "./OddOne.js"
import {
  CalculateBudgetWithSavingsTarget,
  CalculateIncomeAndExpenses,
  CalculateSavingsPotential,
} from "./calculation.js"
import { Categorization } from "./categorization.js"
import { Memory } from "./memory.js"
import { MultipleChoice } from "./multipleChoice.js"
import { Quiz } from "./quiz.js"
import { Text } from "./text.js"
import { TextPuzzle } from "./textpuzzle.js"

import { Video } from "./hlc-video.js"
import { Resolution } from "./resolution.js"

const EXERCISES = {
  calculateIncomeAndExpenses: CalculateIncomeAndExpenses,
  calculateBudgetWithSavingsTarget: CalculateBudgetWithSavingsTarget,
  calculateSavingsPotential: CalculateSavingsPotential,
  categorization: Categorization,
  memory: Memory,
  multipleChoice: MultipleChoice,
  oddOne: OddOne,
  text: Text,
  quiz: Quiz,
  textpuzzle: TextPuzzle,

  hlc_mca: lazy(() => import("../hlc-mca/index.js")),
  hlc_pnc: lazy(() => import("../hlc-pnc/index.js")),
  hlc_video: Video,
  hlc_hpp: lazy(() => import("../hlc-hpp/index.js")),
  hlc_sim: lazy(() => import("../hlc-sim/index.js")),
  hlc_puzzle: lazy(() => import("../hlc-puzzle/index.js")),

  resolution: Resolution,
}

export function exerciseByMethod(method) {
  return EXERCISES[method]
}
