const TYPE_ORDERING = {
  Cape: 10,
  Skin: 20,
  Suit: 30,
  Hair: 40,
  Mask: 50,
  Shoes: 60,
  Accessoire: 70,
  Lifestyle: 80,
}
const typeFromItem = (item) => item.replace(/_[0-9]+(_.+)?/, "")
const hidesHair = ["Mask_01", "Mask_09", "Mask_10"]

const sortLayers = (items) => {
  let arr = Array.from(items)
  arr.sort(
    (a, b) => TYPE_ORDERING[typeFromItem(b)] - TYPE_ORDERING[typeFromItem(a)],
  )
  hidesHair.forEach((item) => {
    if (arr.includes(item)) {
      arr = arr.filter((item) => typeFromItem(item) !== "Hair")
    }
  })
  return arr
}

export const Hero = ({ assetGroup, heroName, heroInfo, items, ...props }) => {
  const layers = sortLayers(items)
  return (
    <>
      <div {...props}>
        {heroInfo ? (
          <div className="hero-heading">
            <h1 className="hero-title">{heroInfo.heroName}</h1>
            <h2 className="hero-levelname">{heroInfo.level?.title}</h2>
          </div>
        ) : null}
        <div
          className="hero-layers"
          style={{
            backgroundImage: layers
              .map((identifier) => `url(${assetGroup[identifier]})`)
              .join(", "),
          }}
        />
        {heroName ? <div className="hero-name">{heroName}</div> : null}
      </div>
      {heroInfo ? (
        <div className="hero-badge">
          <div className="hero-level">
            Level <br />
            {heroInfo.level?.level}
          </div>
          <div className="hero-experience">
            XP <br />
            {heroInfo.experience}
          </div>
          <div className="hero-bucks">
            Bucks <br />
            {heroInfo.bucks}
          </div>
        </div>
      ) : null}
    </>
  )
}
