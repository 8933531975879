import { Suspense, useCallback, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom"

import { exerciseByMethod } from "./exercises/index.js"
import { fetchJSON } from "./fetch.js"
import { gettext } from "./i18n.js"
import { generatePath, routes } from "./routes.js"

function useExercise(url) {
  const [data, setData] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      setData({
        isLoading: true,
        exercise: null,
        error: null,
      })

      try {
        const { exercise } = await fetchJSON(url)
        setData((d) => ({
          ...d,
          exercise,
          isLoading: false,
        }))
      } catch (err) {
        setData((d) => ({
          ...d,
          error: `${gettext("Network error")}: ${err}`,
          isLoading: false,
        }))
      }
    }

    fetchData()
  }, [url])

  return data
}

export function ExerciseLoader(props) {
  const navigate = useNavigate()

  const { exercise, error } = useExercise(props.exercise.url)
  const ExerciseImpl = exerciseByMethod(exercise?.exercise?.method)

  const goToDistrict = useCallback(() => {
    navigate(generatePath(routes.district, props.district))
  }, [navigate, props.district])

  if (!exercise) {
    return (
      <div className="overlay">
        <h1>{gettext("Loading exercise...")}</h1>
        {error ? (
          <>
            <p>{error}</p>
            <button type="button" className="button" onClick={goToDistrict}>
              {gettext("Close")}
            </button>
          </>
        ) : null}
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>{exercise.name} - FinanceMission World</title>
      </Helmet>
      {ExerciseImpl ? (
        <Suspense fallback={gettext("Loading...")}>
          <ExerciseImpl
            {...exercise}
            district={props.district}
            goToDistrict={goToDistrict}
          />
        </Suspense>
      ) : (
        <div className="saving is-error">
          <div className="saving-content">
            <h2>Unknown exercise method «{exercise?.exercise?.method}»</h2>
            <button type="button" className="button" onClick={goToDistrict}>
              {gettext("Close")}
            </button>
          </div>
        </div>
      )}
    </>
  )
}
