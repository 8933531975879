import { useEffect } from "react"

import shuffle from "@anttikissa/fisher-yates-shuffle"
import withScrolling from "react-dnd-scrolling"

import {
  FMWDndProvider,
  useDragWithEmptyPreview as useDrag,
  useDrop,
} from "../dnd.js"
import { gettext } from "../i18n.js"
import { logger, timestamp } from "../utils.js"

import { reducedExercise, useDispatch } from "./base.js"

import { Fullscreen } from "../fullscreen.js"

// const CATEGORY_TYPE = "CategorizationCategory"
const ITEM_TYPE = "CategorizationItem"

function initialStateFromProps(props) {
  return {
    categories: props.categories,
    items: shuffle(props.items),
    itemToCategory: Object.fromEntries(
      props.items.map((item) => [item.id, null]),
    ),
    requireCommentary: props.requireCommentary,
    commentaryQuestion: props.commentaryQuestion,
    commentary: "",
    startedExerciseAt: timestamp(),
  }
}

const START = "START"
const DROP = "DROP"
const SET_COMMENTARY = "SET_COMMENTARY"

const reducer = (state, action) => {
  switch (action.type) {
    case START:
      return {
        ...state,
        startedInteractiveAt: timestamp(),
      }

    case DROP:
      return {
        ...state,
        itemToCategory: {
          ...state.itemToCategory,
          [action.item.id]: action.categoryId,
        },
      }

    case SET_COMMENTARY:
      return {
        ...state,
        commentary: action.commentary,
      }

    default:
      throw new Error(`Unknown action ${action}`)
  }
}

const itemsInCategory = (state, categoryId) =>
  state.items.filter((item) => state.itemToCategory[item.id] === categoryId)

const saveState = (state) => ({
  duration: timestamp() - state.startedInteractiveAt,
  itemToCategory: state.itemToCategory,
  categories: state.categories.map((category) => ({
    ...category,
    items: itemsInCategory(state, category.id),
  })),
  commentaryQuestion: state.commentaryQuestion,
  commentary: state.commentary,
  // TODO Correct or incorrect?
})

const ScrollingComponent = withScrolling("div")
//let fullScreen = false

function CategorizationImpl({ state, setSaveState, isCurrent }) {
  const dispatch = useDispatch()
  useEffect(() => {
    if (isCurrent && !state.startedInteractiveAt) {
      dispatch({ type: START })
    }
  })

  return (
    <Fullscreen allowFullscreen={!state.finishedAt}>
      <FMWDndProvider Preview={ItemPreview}>
        {/* TODO scrollable inside scrollable, this sucks. */}
        <ScrollingComponent className="a-categorization exercise__scrolling-container">
          <div className="a-categorization__dropzones">
            {state.categories.map((category) => (
              <Category
                key={category.id}
                category={category}
                items={itemsInCategory(state, category.id)}
              />
            ))}
          </div>
          <NoCategory items={itemsInCategory(state, null)} />
          <Commentary state={state} />
          <button
            type="button"
            className="button button--save"
            onClick={() => {
              if (document.fullscreenElement) {
                document.exitFullscreen()
              }
              setSaveState(saveState(state))
            }}
          >
            {gettext("Save")}
          </button>
        </ScrollingComponent>
      </FMWDndProvider>
    </Fullscreen>
  )
}

const Category = ({ category, items }) => {
  const dispatch = useDispatch()
  const [{ isOver }, drop] = useDrop({
    accept: ITEM_TYPE,
    collect: (monitor) => ({ isOver: monitor.isOver() }),
    drop: (item, _monitor) => {
      dispatch({ type: DROP, item: item.item, categoryId: category.id })
    },
  })

  return (
    <div className={`a-category ${isOver ? "is-over" : ""}`}>
      <div className="a-category__title">{category.name}</div>
      <div className="a-category__items" ref={drop}>
        {items.map((item) => (
          <Item key={item.id} item={item} />
        ))}
      </div>
    </div>
  )
}

const NoCategory = ({ items }) => {
  const dispatch = useDispatch()
  const [_collectedProps, drop] = useDrop({
    accept: ITEM_TYPE,
    drop: (item, _monitor) => {
      dispatch({ type: DROP, item: item.item, categoryId: null })
    },
  })

  return (
    <div className="a-uncategorized" ref={drop}>
      <h3 className="a-uncategorized__title">
        {gettext("Please categorize the following items")}
      </h3>
      <div className="a-uncategorized__items">
        {items.map((item) => (
          <Item key={item.id} item={item} />
        ))}
      </div>
    </div>
  )
}
const Item = ({ item }) => {
  const [_collectedProps, drag] = useDrag({
    type: ITEM_TYPE,
    item: {
      item,
    },
  })

  return (
    <div className="a-category__item button" ref={drag}>
      {item.name}
    </div>
  )
}

const ItemPreview = ({ item, translate }) => {
  return (
    <div
      style={{
        position: "absolute",
        transform: translate,
        margin: "-20px 0 0 -20px",
        opacity: "0.7",
      }}
    >
      <div className="a-category__item button">{item.item.name}</div>
    </div>
  )
}

function Commentary({ state }) {
  const dispatch = useDispatch()

  if (!state.requireCommentary) return null

  return (
    <div className="a-text__question">
      <label htmlFor="commentary">{state.commentaryQuestion}</label>
      <div className="grow-wrap" data-replicated-value={state.commentary}>
        <textarea
          id="commentary"
          value={state.commentary}
          onChange={(e) =>
            dispatch({
              type: SET_COMMENTARY,
              commentary: e.target.value,
            })
          }
        />
      </div>
    </div>
  )
}

export const Categorization = reducedExercise({
  reducer: logger("Categorization: ", reducer),
  initialStateFromProps,
  Implementation: CategorizationImpl,
})
