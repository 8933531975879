import { useCallback, useEffect, useReducer } from "react"
import { createPortal } from "react-dom"

import { containsJSON, fetchAPI } from "./fetch.js"
import { gettext } from "./i18n.js"
import { logger } from "./utils.js"

const SAVE = "SAVE"
const ERROR = "ERROR"
const SUCCESS = "SUCCESS"
const CLOSE = "CLOSE"

const initialState = () => ({
  showOverlay: false,
})

const reducer = (state, action) => {
  switch (action.type) {
    case SAVE:
      return {
        ...state,
        saveState: action.saveState,
        showOverlay: true,
        responseData: null,
        error: null,
      }
    case ERROR:
      return { ...state, error: action.error }
    case SUCCESS:
      return { ...state, responseData: action.responseData }
    case CLOSE:
      return { ...state, showOverlay: false }
    default:
      throw new Error(`Unknown action ${action}`)
  }
}

export function useSaving({ url, onSuccess }) {
  const [state, dispatch] = useReducer(
    logger("useSaving: ", reducer),
    null,
    initialState,
  )

  useEffect(() => {
    if (!state.saveState) return

    const fn = async () => {
      try {
        const response = await fetchAPI(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(state.saveState),
        })
        if (!containsJSON(response)) {
          throw new Error(response.statusText)
        }
        const responseData = await response.json()
        if (responseData.success) {
          dispatch({ type: SUCCESS, responseData })
        } else {
          dispatch({
            type: ERROR,
            error: `${gettext("Server didn't accept the request")}: ${
              responseData?.error || response.statusText
            }`,
          })
        }
      } catch (err) {
        dispatch({
          type: ERROR,
          error: `${gettext("Network error")}: ${err}`,
        })
      }

      window.scrollTo({ left: 0, top: 0, behavior: "smooth" })
    }

    fn()
  }, [state.saveState, url])

  useEffect(() => {
    if (onSuccess && state.responseData) {
      onSuccess(state.responseData)
    }
  }, [onSuccess, state.responseData])

  const Saving = useCallback(
    ({ children }) => {
      if (state.responseData) {
        return children ? children(state.responseData) : null
      }

      if (!state.showOverlay) {
        return null
      }

      if (state.error) {
        return createPortal(
          <div className="saving is-error">
            <div className="saving-content">
              <p>{state.error}</p>
              <button
                className="button"
                type="button"
                onClick={() => dispatch({ type: CLOSE })}
              >
                {gettext("Dismiss")}
              </button>
            </div>
          </div>,
          document.body,
        )
      }

      // Show overlay, but neither success nor error ==> still saving
      return createPortal(
        <div className="saving is-progress">
          <div className="saving-content">
            <h1>{gettext("Save in progress...")}</h1>
          </div>
        </div>,
        document.body,
      )
    },
    [state, dispatch],
  )

  return {
    Saving,
    setSaveState: useCallback(
      (saveState) => dispatch({ type: SAVE, saveState }),
      [dispatch],
    ),
  }
}
