import { Link } from "react-router-dom"

export function FloatingLink({
  name,
  url,
  position,
  stateClass,
  stateStyle,
  modifierClass,
}) {
  return (
    <Link
      to={url}
      className={`floating-link ${stateClass || ""} ${modifierClass || ""}`}
      style={{
        left: `${position.x}%`,
        top: `${position.y}%`,
        "--completion": `${stateStyle || "0%"}`,
      }}
    >
      <div className="floating-link__description">
        <h3>{name}</h3>
      </div>
    </Link>
  )
}
