import { useCallback, useEffect, useRef } from "react"

import { fetchAPI } from "./fetch.js"
import { gettext } from "./i18n.js"
import { useSettings } from "./utils.js"

export const ReflectionBook = ({ handleClose }) => {
  const { urls } = useSettings()
  const ref = useRef()

  const assignHTML = useCallback((html) => {
    ref.current.innerHTML = html

    const inputs = Array.from(
      ref.current.querySelectorAll('input[type="radio"]'),
    )
    inputs.forEach((input) => {
      input.parentNode.insertAdjacentElement("beforebegin", input)
    })

    const happiness = document.getElementById("id_happiness")
    if (happiness)
      happiness.closest(".field").classList.add("widget--happiness")
  }, [])

  // Initial fetch
  useEffect(() => {
    const _fetch = async () => {
      const response = await fetchAPI(urls.reflectionBook)
      assignHTML(await response.text())
    }
    _fetch()
  }, [assignHTML, urls])

  const onSubmit = async (e) => {
    e.preventDefault()
    const body = new FormData(e.target)
    const response = await fetchAPI(urls.reflectionBook, {
      method: "POST",
      body,
    })
    assignHTML(await response.text())
  }

  const connectLoadMoreHandler = (button) => {
    if (button) {
      ref.current = button

      const handler = async (e) => {
        if (e.target.hasAttribute("data-load-more")) {
          e.preventDefault()
          const response = await fetchAPI(e.target.href)
          e.target.outerHTML = await response.text()
        }
      }

      button.addEventListener("click", handler)
      return () => button.removeEventListener("click", handler)
    }
  }

  return (
    <div className="overlay is-profile is-reflectionbook">
      <button
        type="button"
        className="button button--profile"
        onClick={handleClose}
      >
        &lt; {gettext("Back to the apps")}
      </button>

      <div
        ref={connectLoadMoreHandler}
        className="rb-container apps__container"
        onSubmit={onSubmit}
      />
    </div>
  )
}
