import { useState } from "react"

import { hero } from "./assets.js"

export function useRandomHeroes(classmates, createPolygon, district) {
  const isChallenge = district.district === "challenge"
  return useState(() =>
    classmates.map((u, id) => {
      const assetGroup = (hero[u.sex] || hero.female)[
        isChallenge ? "perspective" : "iso"
      ]
      const h = {
        id,
        assetGroup,
        items: u.equippedItems,
        x: Math.random() * 0.9 + 0.05,
        y: isChallenge
          ? Math.random() * 0.1 + 0.65
          : Math.random() * 0.9 + 0.05,
        heroName: u.heroName,
      }
      h.polygon = createPolygon()
      h.polygon.x = h.x * 1000
      h.polygon.y = h.y * 1000
      h.polygon.isOtherHero = true
      return h
    }),
  )
}

/*
export const RandomHeroes = () => {
  const [randomHeroes, setRandomHeroes] = useRandomHeroes()

  useEffect(() => {
    const interval = setInterval(() => {
      // Do nothing at all
      if (Math.random() < 0.2) return

      setRandomHeroes((randomHeroes) =>
        randomHeroes.map((hero) => {
          if (Math.random() < 0.75) return hero

          return {
            ...hero,
            x: clamp(0.05, hero.x + (Math.random() - 0.5) * 0.015, 0.95),
            y: clamp(0.05, hero.y + (Math.random() - 0.5) * 0.015, 0.95),
          }
        })
      )
    }, 2500)
    return () => clearInterval(interval)
  }, [])

  return (
    <>
      {randomHeroes.map((hero, idx) => (
        <Hero
          key={idx}
          className="hero is-in-district is-other"
          assetGroup={hero.assetGroup}
          items={hero.items}
          style={{
            top: `${hero.x * 100}%`,
            left: `${hero.y * 100}%`,
          }}
        />
      ))}
    </>
  )
}
*/
