export const Timer = ({ timeLimit, isRunning, onTimeUp }) => (
  <div className="timer">
    <div
      className="timer-content"
      onAnimationEnd={onTimeUp}
      style={{
        animationPlayState: isRunning ? "running" : "paused",
        animationDuration: `${timeLimit}s`,
      }}
    />
    <div className="timer-text">{timeLimit}s</div>
  </div>
)
