import { StrictMode } from "react"
import { createRoot } from "react-dom/client"

import { HelmetProvider } from "react-helmet-async"
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom"
import { useErrorBoundary } from "use-error-boundary"

import { City } from "./city.js"
import { ClassBook } from "./classbook.js"
import { DistrictRouter } from "./district.js"
import { NotFound } from "./notFound.js"
import { Onboarding } from "./onboarding.js"
import { Profile } from "./profile.js"
import { ReflectionBook } from "./reflectionbook.js"
import { WorldData, useWorldState } from "./world.js"

import { gettext, pgettext } from "./i18n.js"
import { routes } from "./routes.js"
import { useInitialData, usePersistedState } from "./utils.js"

const CachedDataReset = ({ children }) => {
  const { ErrorBoundary, didCatch, error } = useErrorBoundary()
  if (didCatch) {
    return (
      <div className="saving is-error">
        <div className="saving-content">
          <h1>{gettext("Something went wrong")}</h1>
          <code>{error.message}</code>
          <br />
          <button
            type="button"
            className="button"
            onClick={() => {
              localStorage.clear()
              window.location.reload()
            }}
          >
            {gettext("Clear all locally cached data")}
          </button>
        </div>
      </div>
    )
  }
  return <ErrorBoundary>{children}</ErrorBoundary>
}

const Screens = () => (
  <Routes>
    <Route path={routes.district} element={<DistrictRouter />} />
    <Route path={routes.city} exact element={<City />} />
    <Route default element={<NotFound />} />
  </Routes>
)

const APPS = "apps"
const PROFILE = "profile"
const CLASSBOOK = "classbook"
const REFLECTIONBOOK = "reflectionbook"

const ONBOARDING = "onboarding"
const WELCOME = "welcome"

const Menu = () => {
  const { user, klass, teachedClasses } = useWorldState()
  const { urls } = useInitialData("settings")

  const [overlay, setOverlay] = usePersistedState("overlay", "")
  const routerLocation = useLocation()

  if (overlay !== ONBOARDING && !user?.equippedItems?.length) {
    setOverlay(ONBOARDING)
  } else if (overlay === ONBOARDING && user?.equippedItems?.length) {
    setOverlay("")
  }

  return (
    <>
      <div className="menu">
        {user.isTeacher ? (
          <div className="menu__tile">
            <small>{gettext("Viewing as")}</small>
            <select
              className="menu__select"
              value={klass?.id || "0"}
              onChange={(e) => {
                const url = new URL(window.location.href)
                url.searchParams.set("class", e.target.value)
                window.location.href = url.toString()
              }}
            >
              <option value="0">{gettext("Teacher")}</option>
              {teachedClasses.map((k) => (
                <option key={k.id} value={k.id}>
                  {k.name}
                </option>
              ))}
            </select>
          </div>
        ) : null}
        <div className="menu__tile is-score">
          <div className="menu__userinfo">
            {user.level.title}{" "}
            <small className="menu__level">
              ({pgettext("Hero", "Level")} {user.level.level})
            </small>
          </div>
          <small>
            <span className="menu__experience">{user.experience} XP</span>
            <span className="menu__bucks">{user.bucks} Bucks</span>
          </small>
        </div>
        <button
          type="button"
          className="menu__tile is-profile"
          onClick={() =>
            setOverlay((overlay) => (overlay === APPS ? "" : APPS))
          }
        >
          {gettext("Apps")}
        </button>
        {/*
        <NavLink
          className="menu__tile is-level"
          to={generatePath(routes.shop)}
          end
        >
          {gettext("Shop")}
        </NavLink>
        */}
        <a href={urls.logout} className="menu__tile is-logout">
          {gettext("Log out")}
        </a>
      </div>
      {overlay === APPS && <Apps setOverlay={setOverlay} />}
      {overlay === PROFILE && <Profile closeProfile={() => setOverlay(APPS)} />}
      {overlay === CLASSBOOK && (
        <ClassBook handleClose={() => setOverlay(APPS)} />
      )}
      {overlay === REFLECTIONBOOK && (
        <ReflectionBook handleClose={() => setOverlay(APPS)} />
      )}
      {overlay === ONBOARDING &&
        !routerLocation.pathname.includes("/exercise/") && (
          <Onboarding finishOnboarding={() => setOverlay(WELCOME)} />
        )}
      {overlay === WELCOME && <Welcome startExploring={() => setOverlay("")} />}
    </>
  )
}

const Apps = ({ setOverlay }) => (
  <div className="overlay is-profile is-apps">
    <button
      type="button"
      className="button button--profile"
      onClick={() => setOverlay("")}
    >
      &lt; {gettext("Back to the world")}
    </button>

    <section className="apps apps__container">
      <a href="#" className="apps__app" onClick={() => setOverlay(PROFILE)}>
        <div className="apps__icon" />
        <span className="apps__title">{gettext("Shop & Cloakroom")}</span>
      </a>
      <a href="#" className="apps__app" onClick={() => setOverlay(CLASSBOOK)}>
        <div className="apps__icon" />
        <span className="apps__title">{gettext("Class book")}</span>
      </a>
      <a
        href="#"
        className="apps__app"
        onClick={() => setOverlay(REFLECTIONBOOK)}
      >
        <div className="apps__icon" />
        <span className="apps__title">{gettext("Reflection book")}</span>
      </a>
    </section>
  </div>
)

const Welcome = ({ startExploring }) => {
  const { user } = useWorldState()

  console.log({ user }, user.heroName)

  return (
    <div className="saving is-success">
      <div className="saving-content welcome">
        <h1>{gettext("Welcome to FinanceMission World")}</h1>
        <p>
          {gettext(
            "Let's go, off to the city – discover it, solve the exercises, collect Hero-Bucks and change your avatar. Gain experience, become a hero. And most importantly: gain in security regarding your handling of your finances! Good luck and have fun!",
          )}
        </p>
        <button type="button" className="button" onClick={startExploring}>
          {gettext("Let's go!")}
        </button>
      </div>
    </div>
  )
}

const App = () => {
  const basepath = document.getElementById("the-world").dataset.basepath

  return (
    <StrictMode>
      <CachedDataReset>
        <HelmetProvider>
          <BrowserRouter basename={basepath.replace(/\/$/, "")}>
            <WorldData>
              <Screens />
              <Menu />
            </WorldData>
          </BrowserRouter>
        </HelmetProvider>
      </CachedDataReset>
    </StrictMode>
  )
}

export function renderApp(container) {
  const root = createRoot(container)
  root.render(<App />)
}
